import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Alert,
  IconButton,
  Snackbar,
  useTheme
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import authStore from '../stores/AuthStore';
import Logo from '../assets/images/full-logo-white.png';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const SignInPage = () => {
  const [formData, setFormData] = useState({
    identifier: '',
    password: '',
  });

  const [errors, setErrors] = useState({});
  const [submissionError, setSubmissionError] = useState(null);
  const [identifierLabel, setIdentifierLabel] = useState('Email or Username');
  const [emailValidated, setEmailValidated] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const redirectUrl = new URLSearchParams(location.search).get('redirectUrl') || '/products';

  useEffect(() => {
    if (emailRegex.test(formData.identifier)) {
      setIdentifierLabel('Email (you can also enter your username)');
    } else {
      setIdentifierLabel('Username or Email');
    }
  }, [formData.identifier]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validate = () => {
    const validationErrors = {};
    if (!formData.identifier) {
      validationErrors.identifier = 'Email or Username is required';
    }
    if (!formData.password) {
      validationErrors.password = 'Password is required';
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }

    try {
      await authStore.login(formData.identifier, formData.password);

      if (authStore.needMFA) {
        const mfaRedirectUrl = `/sign-in/mfa${redirectUrl ? `?redirectUrl=${encodeURIComponent(redirectUrl)}` : ''}`;
        navigate(mfaRedirectUrl);
      } else {
        if (authStore.token) {
          navigate(redirectUrl);
        } else {
          throw new Error('User requires MFA.');
        }
      }

    } catch (error) {
      if (error?.message && /Email not verified/.test(error.message)) {
        setEmailValidated(false);
      } else {
        setSubmissionError('Failed to sign in: ' + error.message);
      }
    }
  };

  const signUpUrl = `/sign-up${redirectUrl ? `?redirectUrl=${encodeURIComponent(redirectUrl)}` : ''}`;

  return (
    <Box component="main" sx={{ 
      backgroundImage: `url(${require('../assets/images/physics.png')})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      overflowX: 'hidden'
    }}>
      <Container maxWidth="sm" sx={{ 
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
      }}>
        <Box 
           sx={{m: 2}}
        >
          <img
              src={Logo}
              alt="Ear Physics Logo"
              style={{
                width: '200px',
                height: 'auto',
              }}
            />
        </Box>
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            backdropFilter: 'blur(5px)',
            borderRadius: 2,
            p: 4,
            width: '100%',
            maxWidth: 'sm',
          }}
        >
          <Box sx={{ mb: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <IconButton color="inherit" component={Link} to="/">
              <ArrowBackIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h5"
              gutterBottom
              sx={{ color: theme.palette.text.primary, flex: 1, textAlign: 'center', mr: 4 }}
            >
              Sign In
            </Typography>
          </Box>
          <Typography component="p" variant="body1" align="center" sx={{ mb: 2, color: theme.palette.text.secondary }}>
            Please enter your information to sign in.
          </Typography>
          {submissionError && (
            <Snackbar open={true} autoHideDuration={6000}>
              <Alert severity="error">{submissionError}</Alert>
            </Snackbar>
          )}
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
            <Typography variant="body2" sx={{ color: theme.palette.text.primary, mb: 1 }}>
              {identifierLabel}
            </Typography>
            <TextField
              margin="dense"
              required
              fullWidth
              id="identifier"
              name="identifier"
              autoComplete="identifier"
              value={formData.identifier}
              onChange={handleChange}
              error={Boolean(errors.identifier)}
              placeholder="Enter your username or email"
              helperText={errors.identifier}
              InputProps={{
                style: {
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                },
              }}
            />
            <Typography variant="body2" sx={{ color: theme.palette.text.primary, mt: 2 }}>
              Password
            </Typography>
            <TextField
              margin="dense"
              required
              fullWidth
              id="password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              error={Boolean(errors.password)}
              placeholder="Enter your password"
              helperText={errors.password}
              InputProps={{
                style: {
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              Sign In
            </Button>
            <Button
              fullWidth
              variant="text"
              component={Link}
              to={signUpUrl}
              sx={{
                mt: 1,
                color: theme.palette.text.primary,
              }}
            >
              Don't have an account? Sign Up
            </Button>
            {!emailValidated && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6">
                  An email has been sent to verify your account.
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  Please check your inbox and click the verification link to complete the process.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SignInPage;
