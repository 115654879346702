import { makeAutoObservable, runInAction } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class OrderStore {
  orders = [];
  selectedOrder = null;
  isLoading = false;
  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  getUserOrders = async () => {
    this.isLoading = true;
    this.error = null;
    try {
      const response = await apiHandler.get(`/orders/user/me`);
      runInAction(() => {
        this.orders = response;
      });
      return this.orders;
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
        console.error('Error fetching user orders:', error);
      });
      return [];
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getOrderById = async (orderId) => {
    this.isLoading = true;
    this.error = null;
    try {
      this.selectedOrder = this.orders.find((order) => order._id === orderId);
      if (!this.selectedOrder) {
        const response = await apiHandler.get(`/orders/${orderId}`);
        runInAction(() => {
          this.selectedOrder = response.order;
        });
      }
      return this.selectedOrder;
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
        console.error('Error fetching order by ID:', error);
      });
      return null;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  clearSelectedOrder = () => {
    this.selectedOrder = null;
  };

  clearError = () => {
    this.error = null;
  };
}

export default new OrderStore();
