import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useProductStore } from '../stores/ProductStore';
import {
  Grid,
  TextField,
  InputAdornment,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Container,
  Autocomplete,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme } from '../themes/EarPhysicsTheme';
import PageLayout from '../components/PageLayout';
import ProductCard from '../components/ProductCard';
import debounce from 'lodash.debounce';
import PreorderBanner from '../components/PreorderBanner';

const useSortedFilteredProducts = (products, query, category) => {
  const regexSort = useCallback((a, b) => {
    const getSortRank = (name) => {
      if (/In-Ear Monitor Buds with In-Ear Monitoring System/i.test(name)) return 1;
      if (/IEM.*Ear.*buds/i.test(name)) return 2;
      if (/Ear.*buds/i.test(name)) return 3;
      if (/over.*ear.*headphones/i.test(name)) return 4;
      return 5;
    };
    return getSortRank(a.name) - getSortRank(b.name);
  }, []);

  return products
    .filter((product) => product.isAvailable === true)
    .filter((product) =>
      query ? product.name.toLowerCase().includes(query.toLowerCase()) : true
    )
    .filter((product) =>
      category !== 'all' ? product.category === category : true
    )
    .sort(regexSort);
};

const ProductListPage = observer(() => {
  const productStore = useProductStore();
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    productStore.loadProducts();
  }, [productStore]);

  const filteredProducts = useSortedFilteredProducts(
    productStore.products || [],
    debouncedQuery,
    selectedCategory
  );

  useEffect(() => {
    if (debouncedQuery) {
      const commonSuggestions = productStore.products
        .filter((product) =>
          product.name.toLowerCase().includes(debouncedQuery.toLowerCase())
        )
        .map((product) => product.name)
        .slice(0, 5);
      setSuggestions(commonSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [debouncedQuery, productStore.products]);

  const debounceSearch = useCallback(
    debounce((query) => {
      setDebouncedQuery(query);
    }, 300),
    []
  );

  const handleSearchChange = (event, value) => {
    setSearchQuery(value);
    debounceSearch(value);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <PageLayout pageTitle="Our Products">
        <Container maxWidth="lg" sx={{ py: 4 }}>
          <Box sx={{ my: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <PreorderBanner />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: 2,
              }}
            >
              <Autocomplete
                freeSolo
                value={searchQuery}
                onInputChange={handleSearchChange}
                options={suggestions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search products..."
                    autoComplete="off"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ flex: 1 }}
                  />
                )}
                sx={{ flex: 1 }}
              />

              {productStore.categories && productStore.categories.length > 0 && (
                <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    label="Category"
                  >
                    <MenuItem value="all">All Categories</MenuItem>
                    {(productStore.categories || []).map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          </Box>

          {filteredProducts.length > 0 ? (
            <Grid container spacing={4}>
              {filteredProducts.map((product) => (
                <Grid item key={product._id} xs={12} sm={6} md={4}>
                  <ProductCard product={product} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Typography variant="h6" color="textSecondary">
                No products found.
              </Typography>
            </Box>
          )}
        </Container>
      </PageLayout>
    </ThemeProvider>
  );
});

export default ProductListPage;
