import React from 'react';
import PageLayout from '../components/PageLayout';
import Banner from '../components/Banner';
import {Box} from '@mui/material';
import purple from '../assets/images/00020-1130279688.png';

const images = [
  'https://storage.googleapis.com/ear-physics.appspot.com/468092e9-d56c-4d6b-9aca-a16cbe171a3f_DSC09399.jpg',
  'https://storage.googleapis.com/ear-physics.appspot.com/cb382cae-bd13-4609-b977-a7a390773ea9_DSC09374.jpg',
  'https://storage.googleapis.com/ear-physics.appspot.com/a18fdf3a-c879-499d-aa20-013315c893cd_DSC09345.jpg',
  'https://storage.googleapis.com/ear-physics.appspot.com/468092e9-d56c-4d6b-9aca-a16cbe171a3f_DSC09399.jpg',
];

const sections = [
  {
    title: 'Inspired by the Stage',
    subheader: 'A Mission to Redefine Sound for Musicians',
    content: (
      <>
        It all began with a heavy metal guitarist who came to us, frustrated by the lack of clarity in his on-stage audio. This sparked a journey to bridge the gap between traditional hearing science and high-performance audio. We dove into the world of hearing aids and precision audio engineering, creating technology that brings musicians closer to their sound than ever before.
      </>
    ),
    image: images[0],
  },
  {
    title: 'Revolutionizing Clarity',
    subheader: 'Designed for the Stage and Beyond',
    content: (
      <>
        With a blend of advanced audio engineering and innovative testing methods, we developed a product that brings unparalleled clarity to the stage. Our in-ear monitors are crafted to capture every detail, empowering musicians to perform with precision and confidence.
      </>
    ),
    image: images[1],
  },
  {
    title: 'Advancing Hearing Technology',
    subheader: 'Protecting and Enhancing Hearing',
    content: (
      <>
        Understanding the importance of hearing protection, we integrated features that allow musicians to enjoy their sound without compromising their long-term hearing health. Our monitors offer both high-quality sound and hearing protection, designed for use both on stage and off.
      </>
    ),
    image: images[2],
  },
  {
    title: 'Soon to be an FDA-Registered OTC Hearing Aid', 
    subheader: 'Pre-order and Experience the Future of Hearing',
    content: (
      <>
        Ear Physics is proud to announce that our innovative In Ear Monitor (IEM) pack will soon be registered with the FDA as an over-the-counter (OTC) hearing aid. Experience the future of hearing enhancement with Ear Physics, featuring our patented Adaptive Audio technology designed to deliver crystal-clear sound and personalized hearing support.
      </>
    ),
    image: images[3],
  },
];

const AboutPage = () => {
  return (
    <PageLayout pageTitle="ABOUT US" sections={sections}>
      <Box
        maxWidth="lg"
        sx={{
          alignSelf: 'center',
          mt: 4
        }}>
        <Banner 
          title="Ear Physics: FDA-Registered Hearing Enhancement Coming Soon!"
          subtitle="Pre-order now and be among the first to experience superior sound and hearing support."
          buttonText="Pre-order Now"
          buttonLink="/products" 
          imageSrc={purple}
          imagePosition="right"
        />
      </Box>
    </PageLayout>
  );
};

export default AboutPage;