import { makeAutoObservable } from 'mobx';
import axios from 'axios';

class CartStore {
  cartItems = [];

  constructor() {
    makeAutoObservable(this);
    this.loadCart();
  }

  addToCart(product) {
    const existingItem = this.cartItems.find(item => item._id === product._id);
    if (existingItem) {
      existingItem.quantity += 1;
    } else {
      this.cartItems.push({
        ...product,
        quantity: 1,
        productImage: product.primaryImage?.url || '',
      });
    }
    this.saveCart();
  }

  removeFromCart(id) {
    this.cartItems = this.cartItems.filter(item => item._id !== id);
    this.saveCart();
  }

  increaseQuantity(id) {
    const item = this.cartItems.find(item => item._id === id);
    if (item) {
      item.quantity += 1;
    }
    this.saveCart();
  }

  decreaseQuantity(id) {
    const item = this.cartItems.find(item => item._id === id);
    if (item && item.quantity > 1) {
      item.quantity -= 1;
    } else if (item && item.quantity === 1) {
      this.removeFromCart(id);
    }
    this.saveCart();
  }

  clearCart() {
    this.cartItems = [];
    this.saveCart();
  }

  get total() {
    return this.cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
  }

  loadCart() {
    const savedCart = this.loadCartFromLocal();
    if (!savedCart) {
      this.loadCartFromDB();
    }
  }

  loadCartFromLocal() {
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      this.cartItems = JSON.parse(savedCart);
      return true;
    }
    return false;
  }

  async loadCartFromDB() {
    try {
      const response = await axios.get('/api/cart');
      const data = response.data || [];
      this.cartItems = data.map(item => ({
        ...item,
        productImage: item.product.primaryImage?.url || '',
      }));
      this.saveCartToLocal();
    } catch (error) {
      console.error('Failed to load cart from DB:', error);
    }
  }

  saveCart() {
    this.saveCartToLocal();
    this.saveCartToDB();
  }

  saveCartToLocal() {
    try {
      localStorage.setItem('cart', JSON.stringify(this.cartItems));
    } catch (error) {
      console.error('Failed to save cart locally:', error);
    }
  }

  async saveCartToDB() {
    try {
      await axios.post('/api/cart', { items: this.cartItems });
    } catch (error) {
      console.error('Failed to save cart to DB:', error);
    }
  }
}

const cartStore = new CartStore();
export default cartStore;
