import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Badge,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { observer } from 'mobx-react';
import { alpha } from '@mui/material/styles';
import FullWhite from '../assets/images/full-logo-white.png';
import cartStore from '../stores/CartStore';
import authStore from '../stores/AuthStore';
import NavDrawer from './NavDrawer';
import CartDrawer from './CartDrawer';
import CartButton from './CartButton';

const Navbar = observer(({ transparent = false }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [cartDrawerOpen, setCartDrawerOpen] = useState(false);
  const [bgOpacity, setBgOpacity] = useState(transparent ? 0 : 1);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const navItems = [
    { text: 'Products', link: '/products' },
    { text: 'Cart', link: '/cart' },
    { text: 'Profile', link: '/profile', condition: authStore.isAuthenticated },
    { text: 'Sign In', link: '/sign-in', condition: !authStore.isAuthenticated },
    { text: 'Sign Up', link: '/sign-up', condition: !authStore.isAuthenticated },
    { text: 'Sign Out', link: '/', condition: authStore.isAuthenticated, action: () => authStore.logout() },
    { text: 'About', link: '/about' },
    { text: 'Contact', link: '/contact' },
    { text: 'News', link: '/news' },
    { text: 'Privacy', link: '/privacy' },
    { text: 'Terms', link: '/terms' },
  ];

  const drawerWidth = isMdUp ? 400 : '100vw';

  useEffect(() => {
    let ticking = false;

    const handleScroll = () => {
      if (!transparent) return;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          const currentScrollY = window.scrollY;
          const newOpacity = Math.min(1, currentScrollY / 400);
          setBgOpacity(newOpacity);
          ticking = false;
        });
        ticking = true;
      }
    };

    if (transparent) {
      window.addEventListener('scroll', handleScroll);
      handleScroll();
    } else {
      setBgOpacity(1);
    }

    return () => {
      if (transparent) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [transparent]);

  const toggleDrawer = (open) => () => setDrawerOpen(open);
  const toggleCartDrawer = (open) => () => setCartDrawerOpen(open);

  const isTransparent = transparent || bgOpacity < 0.6;

  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: alpha(theme.palette.background.paper, transparent ? bgOpacity * 0.9 : 0.9),
        boxShadow: 'none',
        backdropFilter: `blur(${Math.ceil(bgOpacity) * 10}px)`,
        zIndex: theme.zIndex.drawer + 1,
        WebkitBackdropFilter: 'blur(10px)',
      }}
    >
      <Toolbar>
        <Button
          component={Link}
          to="/"
          sx={{
            mr: 2,
            ml: -1,
            color: bgOpacity < 0.6 ? theme.palette.common.white : theme.palette.common.black,
          }}
        >
          <img
            src={FullWhite}
            alt="Ear Physics Logo"
            style={{
              width: '100px',
              height: 'auto',
              filter: bgOpacity < 0.6 ? 'invert(0)' : 'invert(1)',
            }}
          />
        </Button>

        <Box sx={{ flexGrow: 1 }} />

        {isMdUp && (
          <>
            <Button
              component={Link}
              to="/products"
              sx={{
                color: bgOpacity < 0.6 ? theme.palette.common.white : theme.palette.common.black,
                transition: 'color 0.3s ease',
              }}
            >
              Products
            </Button>
            <Button
              component={Link}
              to="/news"
              sx={{
                color: bgOpacity < 0.6 ? theme.palette.common.white : theme.palette.common.black,
                transition: 'color 0.3s ease',
              }}
            >
              News
            </Button>
            <Button
              component={Link}
              to="/contact"
              sx={{
                color: bgOpacity < 0.6 ? theme.palette.common.white : theme.palette.common.black,
                transition: 'color 0.3s ease',
              }}
            >
              Contact
            </Button>
            {authStore.isAuthenticated ? (
              <Button
                onClick={() => authStore.logout()}
                sx={{
                  color: bgOpacity < 0.6 ? theme.palette.common.white : theme.palette.common.black,
                  transition: 'color 0.3s ease',
                }}
              >
                Sign Out
              </Button>
            ) : (
              <>
                <Button
                  component={Link}
                  to="/sign-in"
                  sx={{
                    color: bgOpacity < 0.6 ? theme.palette.common.white : theme.palette.common.black,
                    transition: 'color 0.3s ease',
                  }}
                >
                  Sign In
                </Button>
                <Button
                  component={Link}
                  to="/sign-up"
                  sx={{
                    color: bgOpacity < 0.6 ? theme.palette.common.white : theme.palette.common.black,
                    transition: 'color 0.3s ease',
                  }}
                >
                  Sign Up
                </Button>
              </>
            )}
          </>
        )}

        <CartButton 
          itemCount={cartStore.cartItems.reduce((total, item) => total + item.quantity, 0)}
          onClick={toggleCartDrawer(true)}
          color={bgOpacity < 0.6 ? theme.palette.common.white : theme.palette.common.black}
        />

        <IconButton
          aria-label="open drawer"
          edge="end"
          onClick={toggleDrawer(true)}
          sx={{
            ml: 1,
            color: bgOpacity < 0.6 ? theme.palette.common.white : theme.palette.common.black,
            transition: 'color 0.3s ease',
          }}
        >
          <MenuIcon />
        </IconButton>

        <NavDrawer
          open={drawerOpen}
          toggleDrawer={toggleDrawer}
          navItems={navItems.filter((item) => item.condition === undefined || item.condition)}
          drawerWidth={drawerWidth}
        />
        <CartDrawer
          open={cartDrawerOpen}
          toggleCartDrawer={toggleCartDrawer}
          drawerWidth={drawerWidth}
        />
      </Toolbar>
    </AppBar>
  );
});

Navbar.propTypes = {
  transparent: PropTypes.bool,
};

export default Navbar;
