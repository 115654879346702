import { makeAutoObservable, runInAction } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class ContactStore {
  isLoading = false;
  error = null;
  successMessage = null; 

  constructor() {
    makeAutoObservable(this);
  }

  submitContactForm = async (contactData) => {
    this.isLoading = true;
    this.error = null;
    this.successMessage = null; 

    try {
      const response = await apiHandler.post('/contact/submit', contactData); 
      runInAction(() => {
        this.successMessage = "Message sent successfully!"; 
      });
      return response.data; 
    } catch (error) {
      runInAction(() => {
        this.error = error.message; 
        console.error('Error submitting contact form:', error);
      });
      return null;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  clearError = () => {
    this.error = null;
  };

  clearSuccessMessage = () => {
    this.successMessage = null;
  };
}

const contactStore = new ContactStore();
export default contactStore;