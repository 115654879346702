import React from 'react';
import { observer } from 'mobx-react';
import cartStore from '../stores/CartStore';
import { Typography, IconButton, List, ListItem, ListItemText, Divider, Box, Button, Avatar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';

const CartList = observer(({ onClose, onReturn }) => (
  <Box>
    <List>
      {cartStore.cartItems.map((item) => (
        <ListItem
          key={item._id}
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Avatar
            src={item.productImage || 'https://via.placeholder.com/64?text=No+Image'}
            alt={item.name}
            variant="square"
            sx={{ width: 64, height: 64, mr: 2, bgcolor: item.productImage ? 'transparent' : 'grey.200' }}
          />
          <ListItemText
            primary={item.name}
            secondary={`$${item.price} x ${item.quantity}`}
            sx={{ flex: 1 }}
          />
          <IconButton
            onClick={() => cartStore.decreaseQuantity(item._id)}
            sx={{ color: 'text.primary' }}
            aria-label="decrease quantity"
          >
            <RemoveIcon />
          </IconButton>
          <Typography mx={2}>{item.quantity}</Typography>
          <IconButton
            onClick={() => cartStore.increaseQuantity(item._id)}
            sx={{ color: 'text.primary' }}
            aria-label="increase quantity"
          >
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={() => cartStore.removeFromCart(item._id)}
            sx={{ color: 'text.primary', ml: 2 }}
            aria-label="remove item"
          >
            <DeleteIcon />
          </IconButton>
        </ListItem>
      ))}
    </List>
    <Divider sx={{ my: 2 }} />
    <Typography variant="h6" textAlign="center">
      Total: ${cartStore.total.toFixed(2)}
    </Typography>
    <Button
      variant="outlined"
      color="primary"
      onClick={() => onClose && onClose()}
      sx={{
        display: 'block',
        mx: 'auto',
        mt: 2,
        width: { xs: '100%', md: '300px' }
      }}
    >
      Proceed to Checkout
    </Button>
    <Button
      variant="outlined"
      color="primary"
      onClick={() => onReturn && onReturn()}
      sx={{
        display: 'block',
        mx: 'auto',
        mt: 2,
        width: { xs: '100%', md: '300px' }
      }}
    >
      Shop Now
    </Button>
  </Box>
));

export default CartList;
