import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Box, Divider, Typography, IconButton, useMediaQuery, Tooltip } from '@mui/material';
import { AccountCircle, History, Settings, Devices, AddCircleOutline, Build, Audiotrack, GraphicEq, Equalizer, Info, BarChart, Book, SupportAgent, HelpOutline } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
const AccountSidebar = () => {
  const theme = useTheme();
  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = 300;
  const [open, setOpen] = useState(!isSmallScreen);

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  const sections = [
    {
      title: 'User Account',
      items: [
        { text: 'Profile', icon: <AccountCircle />, path: '/profile' },
        { text: 'Order History', icon: <History />, path: '/order-history' },
        // { text: 'Account Settings', icon: <Settings />, path: '/settings' },
      ],
    },
    // {
    //   title: 'Device Management',
    //   items: [
    //     { text: 'Registered Devices', icon: <Devices />, path: '/devices' },
    //     { text: 'Register New Device', icon: <AddCircleOutline />, path: '/devices/register' },
    //     { text: 'Device Maintenance', icon: <Build />, path: '/devices/maintenance' },
    //   ],
    // },
    // {
    //   title: 'Hearing & Response Management',
    //   items: [
    //     { text: 'Hearing Test', icon: <Audiotrack />, path: '/hearing-test' },
    //     { text: 'Equalization Settings', icon: <GraphicEq />, path: '/equalization' },
    //     { text: 'Device Profiles', icon: <Equalizer />, path: '/device-profiles' },
    //     { text: 'Usage Analytics', icon: <BarChart />, path: '/usage-analytics' },
    //   ],
    // },
    // {
    //   title: 'Additional Resources',
    //   items: [
    //     { text: 'User Guides', icon: <Book />, path: '/user-guides' },
    //     { text: 'Support', icon: <SupportAgent />, path: '/support' },
    //     { text: 'Documentation', icon: <HelpOutline />, path: '/documentation' },
    //   ],
    // },
  ];

  const renderSection = (section) => (
    <Box key={section.title}>
      <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary, fontWeight: 'bold', ml: 2, mt: 1 }}>
        {section.title}
      </Typography>
      <List>
        {section.items.map((item) => (
          <ListItem
            key={item.text}
            component={Link}
            to={item.path}
            selected={location.pathname === item.path}
            sx={{
              color: location.pathname === item.path ? theme.palette.text.secondary : theme.palette.text.primary,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <ListItemIcon sx={{ color: location.pathname === item.path ? theme.palette.text.secondary : theme.palette.text.primary }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Divider sx={{ my: 2 }} />
    </Box>
  );

  return (
    <>
     {isSmallScreen && (
        <Box sx={{ position: 'fixed', top: 84, left: 16 }}>
            <Tooltip title="Account Menu" arrow>
            <IconButton onClick={handleToggleDrawer}>
                <MenuOpenIcon />
            </IconButton>
            </Tooltip>
        </Box>
     )}
      <Drawer
        variant={isSmallScreen ? "temporary" : "permanent"}
        open={open}
        onClose={handleToggleDrawer}
        anchor="left"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: '#fbfbfb' },
        }}
      >
        <Box sx={{ p: 3, mt: '72px' }}>
          <Typography variant="h6" sx={{ color: theme.palette.text.primary, fontWeight: 'bold', textAlign: 'left' }}>
            User Account
          </Typography>
          <Typography variant="subtitle2" sx={{ color: theme.palette.text.secondary, textAlign: 'left' }}>
            Manage your profile and devices
          </Typography>
        </Box>

        <Divider />

        {sections.map(renderSection)}
      </Drawer>
    </>
  );
};

export default AccountSidebar;
