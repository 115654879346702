import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams, Link } from 'react-router-dom';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  Box,
  ListItemAvatar,
  Button,
  Container
} from '@mui/material';
import PageLayout from '../components/PageLayout';
import AccountSidebar from '../components/AccountSidebar';
import orderStore from '../stores/OrderStore';
import { useTheme } from '@mui/material/styles';

const OrderDetailsPage = observer(() => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchOrder = async () => {
      const orderData = await orderStore.getOrderById(orderId);
      setOrder(orderData);
    };
    fetchOrder();
  }, [orderId]);

  if (!order) return <Typography>Loading order details...</Typography>;

  return (
    <PageLayout pageTitle={`Order #${order.orderNumber}`}>
      <Box sx={{ display: 'flex', mt: 4, bgcolor: theme.palette.background.default }}>
        <AccountSidebar />
        <Container sx={{ m: 4, flex: 1 }}>
          <Typography variant="h4" gutterBottom>
            Order Details
          </Typography>

          <Box sx={{ backgroundColor: theme.palette.background.paper, borderRadius: 2, p: 2, boxShadow: 1 }}>
            <ListItemText primary="Order Status" secondary={order.status} />
            <ListItemText primary="Subtotal" secondary={`${order.subtotal || order.subtotal === 0 ? order.subtotal.toFixed(2) : 'N/A'} ${order.currency}`} />
            <ListItemText primary="Shipping And Handling" secondary={`${order.shippingAndHandling || order.shippingAndHandling === 0 ? order.shippingAndHandling.toFixed(2) : 'N/A'} ${order.currency}`} />
            <ListItemText primary="Taxes" secondary={`${order.taxes || order.taxes === 0 ? order.taxes.toFixed(2) : 'N/A'} ${order.currency}`} />
            <ListItemText primary="Total Amount" secondary={`${order.totalAmount || order.totalAmount === 0 ? order.totalAmount : 'N/A'} ${order.currency}`} />

            <ListItemText primary="Shipping Status" secondary={order.shippingStatus || "Not available"} />
            {order.trackingNumber && (
              <ListItemText primary="Tracking Number" secondary={order.trackingNumber} />
            )}
            {order.shippingAddress && (
              <ListItemText
                primary="Shipping Address"
                secondary={
                  <>
                    {order.shippingAddress.businessName && (
                      <Typography>{order.shippingAddress.businessName}</Typography>
                    )}
                    <Typography>
                      {order.shippingAddress.street}
                      {order.shippingAddress.line2 && `, ${order.shippingAddress.line2}`}
                    </Typography>
                    <Typography>
                      {order.shippingAddress.city}, {order.shippingAddress.state}{" "}
                      {order.shippingAddress.postalCode}
                    </Typography>
                    {order.shippingAddress.country && (
                      <Typography>{order.shippingAddress.country}</Typography>
                    )}
                  </>
                }
              />
            )}
            <ListItemText primary="Order Notes" secondary={order.note} />
          </Box>

          <Typography variant="h6" sx={{ mt: 4, fontWeight: 'bold' }}>
            Items
          </Typography>
          <List>
            {order.items.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem alignItems="flex-start" sx={{ backgroundColor: theme.palette.background.paper, borderRadius: 2, mb: 2, p: 2, boxShadow: 1 }}>
                  <ListItemAvatar>
                    <Avatar variant="square" src={item.productImage} alt={item.name} sx={{ width: 50, height: 50, mr: 2 }} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Link to={`/products/${item.productId}`} style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
                        {item.name}
                      </Link>
                    }
                    secondary={`Quantity: ${item.quantity} | Price: ${item.price} ${order.currency}`}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>

          {order.downloadLinks?.length > 0 && (
            <Box mt={2}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Download Links
              </Typography>
              <ul>
                {order.downloadLinks.map((link, index) => (
                  <li key={index}>
                    <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.primary.main }}>
                      Download Item {index + 1}
                    </a>
                  </li>
                ))}
              </ul>
            </Box>
          )}

          <Box mt={4} display="flex" justifyContent="flex-end">
            <Button
              component={Link}
              to="/order-history"
              variant="outlined"
              sx={{
                color: theme.palette.text.primary,
                borderColor: theme.palette.text.primary,
                '&:hover': {
                  borderColor: theme.palette.text.primary,
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              Back to Order History
            </Button>
          </Box>
        </Container>
      </Box>
    </PageLayout>
  );
});

export default OrderDetailsPage;
