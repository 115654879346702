import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Badge, Box } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { observer } from 'mobx-react';

class CartButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldAnimate: false
    };
    this.lastActiveTime = Date.now();
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
  }

  componentDidMount() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }

  handleVisibilityChange() {
    if (!document.hidden && this.props.itemCount > 0) {
      const now = Date.now();
      const timeSinceLastActive = now - this.lastActiveTime;
      
      if (timeSinceLastActive > (1000)) {
        this.setState({ shouldAnimate: true });
        setTimeout(() => this.setState({ shouldAnimate: false }), 1200);
      }
    }
    this.lastActiveTime = Date.now();
  }

  render() {
    const { itemCount, onClick, color } = this.props;
    const { shouldAnimate } = this.state;

    return (
      <Box
        sx={{
          position: 'relative',
          '@keyframes wiggle': {
            '0%': { transform: 'rotate(0deg)' },
            '15%': { transform: 'rotate(-15deg)' },
            '30%': { transform: 'rotate(12deg)' },
            '45%': { transform: 'rotate(-10deg)' },
            '60%': { transform: 'rotate(8deg)' },
            '75%': { transform: 'rotate(-5deg)' },
            '85%': { transform: 'rotate(3deg)' },
            '92%': { transform: 'rotate(-2deg)' },
            '100%': { transform: 'rotate(0deg)' }
          }
        }}
      >
        <IconButton
          onClick={onClick}
          sx={{
            color,
            transition: 'color 0.3s ease',
            animation: shouldAnimate ? 'wiggle 1.2s ease-in-out' : 'none',
            transformOrigin: 'center center'
          }}
        >
          <Badge 
            badgeContent={itemCount} 
            color="secondary"
          >
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      </Box>
    );
  }
}

CartButton.propTypes = {
  itemCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  pulseThreshold: PropTypes.number,
};

export default observer(CartButton);