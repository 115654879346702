import React from 'react';
import { observer } from 'mobx-react';
import { Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const OrderConfirmationPage = observer(() => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>Thank You for Your Order!</Typography>
      <Typography variant="body1">Your order has been successfully placed. We’ll send a confirmation email shortly.</Typography>
      <Button component={Link} to="/products" variant="outlined" color="primary">
        Continue Shopping
      </Button>
    </Container>
  );
});

export default OrderConfirmationPage;