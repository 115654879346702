import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import cartStore from '../stores/CartStore';
import { Typography, Container, Paper, Button, Link as MuiLink, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import CartList from '../components/CartList';

const CartPage = observer(() => {
  const navigate = useNavigate();

  useEffect(() => {
    cartStore.loadCart();
  }, []);

  const handleProceedToCheckout = () => {
    if (cartStore.cartItems.length > 0) {
      navigate('/checkout');
    } else {
      alert('Your cart is empty. Add some items first.');
    }
  };

  const handleContinueShoppng = () => {
    navigate('/products');
  }

  return (
    <PageLayout pageTitle="Your Cart">
      <Container component="main" sx={{ my: 4, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Paper
          elevation={12}
          sx={{
            width: '100%',
            borderRadius: 2,
            p: 4,
            maxWidth: 'md',
            backgroundColor: 'rgba(240, 240, 240, 0.3)',
            borderRadius: 2,
          }}
        >
          {cartStore.cartItems.length === 0 ? (
           <>
           <Typography variant="h5" fontWeight="bold" sx={{ mb: 1, textAlign: 'center' }}>
             Your Cart is empty.
           </Typography>
           <Typography variant="body1" sx={{ mb: 2, textAlign: 'center'}}>
             Check out our other products, and add them to the cart.
           </Typography>
           <Box sx={{ display: 'flex', justifyContent: 'center' }}>
             <MuiLink component={Link} to="/products" underline="none">
               <Button variant="outlined" sx={{ width: '300px' }}>
                 See Products
               </Button>
             </MuiLink>
           </Box>
         </>
          ) : (
            <CartList onClose={handleProceedToCheckout} onReturn={handleContinueShoppng} />
          )}

        </Paper>
      </Container>
    </PageLayout>
  );
});

export default CartPage;
