import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useProductStore } from '../stores/ProductStore';
import PageLayout from '../components/PageLayout';
import { Modal, Box, Fade } from '@mui/material';
import VideoBanner from '../components/VideoBanner';
import Banner from '../components/Banner';

const LandingPage = observer(() => {
  const productStore = useProductStore();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    productStore.loadProducts();
  }, [productStore]);

  useEffect(() => {
    const showModalOnce = () => {
      const hasSeenModal = sessionStorage.getItem('hasSeenModal');
      if (!hasSeenModal) {
        const timer = setTimeout(() => {
          setIsModalOpen(true);
          sessionStorage.setItem('hasSeenModal', 'true');
        }, 15000);
        return () => clearTimeout(timer);
      }
    };
    showModalOnce();
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const carouselSections = [
    {
      text: "Ear Physics: Superior Sound, Personalized Hearing",
      subheader: "Experience the future of listening with our innovative IEMs.",
    },
    {
      text: "Pre-Order Now: Ear Physics IEMs - The Future of Listening",
      subheader: "Prepare for the ultimate sound experience, with future hearing aid capabilities.",
    },
    {
      text: "Advanced IEMs with Upcoming Hearing Aid Features",
      subheader: "Engineered for today, designed for tomorrow’s hearing needs.",
    },
    {
      text: "Adaptive Response IEMs",
      subheader: "Hear Your Music Like Never Before",
    },
    {
      text: "High Fidelity On the Go",
      subheader: "Take studio-quality sound wherever life takes you.",
    },
  ];

  const contentSections = [
    {
      title: "Ear Physics: Superior Sound, Personalized Hearing",
      subheader: "Experience unmatched clarity and personalized audio.",
      content: "Our In-Ear Monitors are designed for musicians, audiophiles, and anyone seeking an exceptional listening experience. Feel every detail with advanced audio technology, whether on stage, in the studio, or on the go.",
      image: productStore.products[0]?.primaryImage?.url,
    },
    {
      title: "Future-Ready with FDA Approval Pending",
      subheader: "Soon to be available as over-the-counter hearing aids",
      content: "Ear Physics is awaiting FDA approval to register our IEMs as OTC hearing aids. Once approved, a simple software update will introduce a guided hearing test and our patented Adaptive Audio technology, providing customized hearing correction tailored for music.",
      image: productStore.products[1]?.primaryImage?.url,
    },
    {
      title: "Get the Sound You Deserve",
      subheader: "Adaptive Response IEMs: Made for Musicians",
      content: "Our Adaptive Response IEMs give you a clean, personalized sound that lets you catch every detail. No more ear fatigue—just hours of listening comfort that adapts to you.",
      image: productStore.products[2]?.primaryImage?.url,
    },
    {
      title: "Pre-order Now to Experience the Future of Sound",
      subheader: "Superior audio now, hearing enhancement features soon.",
      content: "With a pre-order, you’re not only securing high-fidelity IEMs but also access to potential hearing aid capabilities. Ear Physics IEMs offer adaptive sound technology to provide you with a comfortable, immersive listening experience.",
      image: productStore.products[3]?.primaryImage?.url,
    },
    {
      title: "Where Technology Meets Real Sound",
      subheader: "Next-Gen Sound Tech",
      content: "Step into the future of sound with our Adaptive Response IEMs. They adjust to your ear's unique shape, giving you a listening experience that’s all about you.",
      image: productStore.products[4]?.primaryImage?.url,
    },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselSections.length);
    }, 4000);

    return () => clearInterval(timer);
  }, [carouselSections.length]);

  return (
    <PageLayout pageTitle="Ear Physics - Where High-Fidelity Audio Meets Hearing Enhancement" sections={contentSections} navbarTransparent={true}>
      {carouselSections.length > 0 && (
        <VideoBanner
          videoId="1023423745"
          title={carouselSections[currentIndex].text}
          subheader={carouselSections[currentIndex].subheader}
          scrollTargetId="main-content"
        />
      )}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Fade in={isModalOpen}>
          <Box sx={{ width: '100%', maxWidth: 600, px: 2 }}>
            <Banner
              title="Pre-order Now and Elevate Your Listening Experience"
              subtitle="Secure your Adaptive Response IEMs and experience superior sound quality."
              description="Be ready for future updates that unlock hearing aid features and personalized sound enhancement."
              buttonText="Pre-order Now"
              buttonLink="/products"
              imageSrc={productStore.products[0]?.primaryImage?.url}
              imagePosition="left"
            />
          </Box>
        </Fade>
      </Modal>
    </PageLayout>
  );
});

export default LandingPage;
