import React from 'react';
import { Box, Typography, Button, Paper, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const Banner = ({
  title,
  subtitle,
  description,
  buttonText,
  buttonLink,
  imageSrc,
  imagePosition = 'left', // 'left' | 'right' | 'background'
  imageStyle = 'contained', // 'contained' | 'full'
}) => {
  const theme = useTheme();

  return (
    <Paper 
      elevation={16} 
      sx={{
        display: 'flex',
        maxHeight: { xs: 'auto', md: '350px' },
        flexDirection: {xs: 'column', md: imagePosition === 'right' ? 'row-reverse' : 'row'},
        alignItems: 'center',
        position: imagePosition === 'background' ? 'relative' : 'static',
        overflow: 'hidden',
        backgroundColor: imagePosition === 'background' && imageStyle === 'full' ? 'transparent' : theme.palette.background.default,
        borderRadius: imagePosition === 'background' && imageStyle === 'full' ? 0 : 2,
        mb: 4,
        height: 'auto',
      }}
    >
      {imageSrc && imagePosition === 'background' && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${imageSrc})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: -1,
          }}
        />
      )}

      {imageSrc && (imagePosition === 'left' || imagePosition === 'right') && (
        <Box
          component="img"
          src={imageSrc}
          alt={title}
          sx={{
            width: {xs: '100%', md:'50%'},
            height: '100%',
            maxHeight: {xs: '250px', md:'100%'},
            objectFit: {xs: 'cover', md: imageStyle === 'contained'? 'contain' : 'cover'},
            borderRadius: {xs: 'none', md: imagePosition === 'left' ? '8px 0 0 8px' : '0 8px 8px 0'},
          }}
        />
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: imagePosition === 'background' ? 'center' : 'flex-start',
          textAlign: imagePosition === 'background' ? 'center' : 'left',
          width: {sm: '90%', md:imagePosition === 'background' ? '100%' : '50%'},
          m: 2
        }}
      >
        <Typography variant="h5" fontWeight="bold" sx={{ mb: 1 }}>
          {title}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {subtitle}
        </Typography>
        <Typography variant="caption" sx={{ mb: 2 }}>
          {description}
        </Typography>
        <MuiLink component={Link} to={buttonLink} underline="none" sx={{width: '100%', mb: 2}}>
          <Button variant="outlined" sx={{width: '100%'}}>{buttonText}</Button>
        </MuiLink>
      </Box>
    </Paper>
  );
};

export default Banner;