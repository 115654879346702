import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Container, Alert, Snackbar, CircularProgress, IconButton, useTheme } from '@mui/material';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Logo from '../assets/images/full-logo-white.png';
import authStore from '../stores/AuthStore';

const MFA = ({ userEmail }) => {
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const redirectUrl = new URLSearchParams(location.search).get('redirectUrl') || '/products';

  const handleCloseToast = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  const handleSendCode = async () => {
    setIsLoading(true);
    try {
      await authStore.sendMFAEmail();
      setToastMessage('MFA code sent via Email successfully.');
      setShowToast(true);
    } catch (error) {
      setToastMessage(error.message || 'Failed to send MFA code via Email.');
      setShowToast(true);
    }
    setIsLoading(false);
  };

  const handleVerifyCode = async () => {
    if (!code.trim()) {
      setToastMessage('Please enter the MFA code.');
      setShowToast(true);
      return;
    }
    setIsLoading(true);
    try {
      const response = await authStore.verifyMFA(code);
      if (response) {
        setToastMessage('MFA code verified successfully.');
        navigate(redirectUrl);
      } else {
        throw new Error(response.message || 'MFA code verification failed.');
      }
    } catch (error) {
      setToastMessage(error.message || 'MFA code verification failed.');
    } finally {
      setIsLoading(false);
      setShowToast(true);
    }
  };

  return (
    <Box component="main" sx={{ 
      backgroundImage: `url(${require('../assets/images/physics.png')})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      overflowX: 'hidden'
    }}>
      <Container maxWidth="sm" sx={{ 
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
      }}>
        <Box 
           sx={{m: 2}}
        >
          <img
              src={Logo}
              alt="Ear Physics Logo"
              style={{
                width: '200px',
                height: 'auto',
              }}
            />
        </Box>
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            backdropFilter: 'blur(5px)',
            borderRadius: 2,
            p: 4,
            width: '100%',
            maxWidth: 'sm',
          }}
        >
        <Typography component="h1" variant="h5" align="center" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Multi-Factor Authentication
        </Typography>
        <Typography component="p" variant="body1" align="center" sx={{ mb: 2, color: theme.palette.text.secondary }}>
          As an extra layer of security, we sent a code to your email.
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="code"
            label="Enter MFA Code"
            name="code"
            placeholder="123456"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            InputLabelProps={{
              style: { color: theme.palette.text.primary },
            }}
            InputProps={{
              style: {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              },
            }}
          />
          <Button
            fullWidth
            variant="outlined"
            onClick={handleVerifyCode}
            disabled={isLoading}
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            Verify Code
          </Button>
          <Button
            fullWidth
            variant="text"
            onClick={handleSendCode}
            disabled={isLoading}
            sx={{ color: theme.palette.text.primary }}
          >
            Resend Code
          </Button>
          {isLoading && <CircularProgress size={24} sx={{ mt: 2 }} />}
        </Box>
        </Box>
        {toastMessage && (
          <Snackbar open={showToast} autoHideDuration={6000} onClose={handleCloseToast}>
            <Alert onClose={handleCloseToast} severity={toastMessage.includes('successfully') ? 'success' : 'error'} sx={{ width: '100%' }}>
              {toastMessage}
            </Alert>
          </Snackbar>
        )}
      </Container>
    </Box>
  );
};

export default MFA;
