import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Drawer,
  Box,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Button,
  IconButton,
  Typography,
  ThemeProvider,
  Link
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FullWhite from '../assets/images/full-logo-white.png';
import { darkTheme } from '../themes/EarPhysicsTheme';

const NavDrawer = ({ open, toggleDrawer, navItems, drawerWidth }) => {
  const navigate = useNavigate();

  const handleItemClick = (item) => (event) => {
    event.preventDefault();
    toggleDrawer(false)();
    if (item.action) {
      item.action();
    }
    navigate(item.link);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, }}
        PaperProps={{
          sx: {
            width: drawerWidth,
            backgroundColor: 'black',
            color: darkTheme.palette.text.primary,
          },
        }}
      >
        <Box role="presentation" sx={{ height: '100%', display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Button 
              color="inherit" 
              component={RouterLink} 
              to="/" 
              onClick={toggleDrawer(false)}
            >
              <img src={FullWhite} alt="Ear Physics Logo" style={{ width: '100px', height: 'auto' }} />
            </Button>
            <IconButton color="inherit" onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List sx={{ flexGrow: 1, p: 2 }}>
            {navItems.map((item) => (
              <ListItem 
                button 
                onClick={handleItemClick(item)} 
                key={item.text}
              >
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
          <Box sx={{ p: 3, mt: 'auto' }}>
            <Typography variant="body1" sx={{ mb: 3 }}>
              © {new Date().getFullYear()} Ear Physics - Manufactured with{' '}
              <Box component="span" sx={{ color: 'red', mx: 0.5 }}>
                &#10084;
              </Box>
              in Michigan
            </Typography>
            <Typography variant="caption" sx={{ color: darkTheme.palette.text.secondary }}>
              <Link
                href="https://patents.google.com/patent/US20200177147A1/en"
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                sx={{ color: 'inherit' }}
              >
                US Patent 11,641,183
              </Link>
              ,&nbsp;
              <Link
                href="https://patents.google.com/patent/US12101605B2/en"
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                sx={{ color: 'inherit' }}
              >
                US Patent 12,101,605
              </Link>
              , other patents pending.
            </Typography>
          </Box>
        </Box>
      </Drawer>
    </ThemeProvider>
  );
};

export default NavDrawer;