import React, { useRef, useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Link as MuiLink,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Alert
} from '@mui/material';
import { Link } from 'react-router-dom';
import Player from '@vimeo/player';

const VimeoPlayer = ({ videoId, title, originalUrl }) => {
  const iframeRef = useRef(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    let player;
    try {
      player = new Player(iframeRef.current);
      player.on('error', () => setError(true));
      return () => player?.destroy?.();
    } catch (error) {
      console.error("Error creating Vimeo player:", error);
      setError(true);
    }
  }, [videoId]);

  if (error) {
    return (
      <MuiLink
        href={originalUrl}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          color: 'primary.main',
          textDecoration: 'none',
          '&:hover': { textDecoration: 'underline' },
        }}
      >
        {title || 'Watch on Vimeo'}
      </MuiLink>
    );
  }

  return (
    <Box>
      <Box sx={{ mt: 2, mb: 2, width: "100%", aspectRatio: "16/9" }}>
        <iframe
          ref={iframeRef}
          src={`https://player.vimeo.com/video/${videoId}`}
          width="100%"
          height="100%"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          title={title || `Vimeo video ${videoId}`}
        />
      </Box>
      <MuiLink
        href={originalUrl}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          color: 'primary.main',
          textDecoration: 'none',
          '&:hover': { textDecoration: 'underline' },
          display: 'block',
          mb: 2,
          fontSize: '0.875rem'
        }}
      >
        {title || 'Watch on Vimeo'}
      </MuiLink>
    </Box>
  );
};

const YouTubePlayer = ({ videoId, title, originalUrl }) => {
  const [error, setError] = useState(false);

  if (error) {
    return (
      <MuiLink
        href={originalUrl}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          color: 'primary.main',
          textDecoration: 'none',
          '&:hover': { textDecoration: 'underline' },
        }}
      >
        {title || 'Watch on YouTube'}
      </MuiLink>
    );
  }

  return (
    <Box>
      <Box sx={{ mt: 2, mb: 2, width: "100%", aspectRatio: "16/9" }}>
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${videoId}`}
          title={title || `YouTube video ${videoId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          onError={() => setError(true)}
        />
      </Box>
      <MuiLink
        href={originalUrl}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          color: 'primary.main',
          textDecoration: 'none',
          '&:hover': { textDecoration: 'underline' },
          display: 'block',
          mb: 2,
          fontSize: '0.875rem'
        }}
      >
        {title || 'Watch on YouTube'}
      </MuiLink>
    </Box>
  );
};

const parseVideoUrl = (url) => {
  try {
    const urlObj = new URL(url);
    
    if (urlObj.hostname.includes('vimeo.com')) {
      const videoId = urlObj.pathname.split('/').pop().split('?')[0];
      if (videoId.match(/^\d+$/)) {
        return { type: 'vimeo', id: videoId };
      }
    }
    
    if (urlObj.hostname.includes('youtube.com') || urlObj.hostname.includes('youtu.be')) {
      let videoId;
      if (urlObj.hostname.includes('youtu.be')) {
        videoId = urlObj.pathname.slice(1).split('?')[0];
      } else {
        videoId = urlObj.searchParams.get('v');
      }
      if (videoId) return { type: 'youtube', id: videoId };
    }
    
    return null;
  } catch (e) {
    console.error('Error parsing video URL:', e);
    return null;
  }
};

const MarkdownRenderer = ({ markdown }) => {
  const parseInlineElements = (text) => {
    const elements = [];
    let currentText = '';
    let key = 0;

    const pushCurrentText = () => {
      if (currentText) {
        elements.push(currentText);
        currentText = '';
      }
    };

    let pos = 0;
    while (pos < text.length) {
      const patterns = [
        { regex: /`([^`]+)`/, type: 'code' },
        { regex: /\*\*([^*]+)\*\*/, type: 'bold' },
        { regex: /\*([^*]+)\*/, type: 'italic' },
        { regex: /\[([^\]]+)\]\(([^)]+)\)/, type: 'link' },
        { regex: /(https?:\/\/[^\s]+)/, type: 'url' }
      ];

      let earliestMatch = null;
      let earliestIndex = text.length;

      for (const pattern of patterns) {
        const match = text.slice(pos).match(pattern.regex);
        if (match && match.index < earliestIndex) {
          earliestMatch = { ...match, type: pattern.type };
          earliestIndex = match.index;
        }
      }

      if (!earliestMatch) {
        currentText += text.slice(pos);
        break;
      }

      currentText += text.slice(pos, pos + earliestIndex);
      pushCurrentText();

      const matchText = earliestMatch[1];
      const url = earliestMatch[2];

      switch (earliestMatch.type) {
        case 'code':
          elements.push(
            <Box
              key={key++}
              component="code"
              sx={{
                backgroundColor: 'grey.100',
                p: 0.5,
                borderRadius: 0.5,
                fontFamily: 'monospace'
              }}
            >
              {matchText}
            </Box>
          );
          break;

        case 'bold':
          elements.push(
            <Typography key={key++} component="span" sx={{ fontWeight: 'bold' }}>
              {matchText}
            </Typography>
          );
          break;

        case 'italic':
          elements.push(
            <Typography key={key++} component="span" sx={{ fontStyle: 'italic' }}>
              {matchText}
            </Typography>
          );
          break;

        case 'link':
        case 'url': {
          const linkUrl = url || matchText;
          const linkText = earliestMatch.type === 'link' ? matchText : linkUrl;
          const videoInfo = parseVideoUrl(linkUrl);

          if (videoInfo) {
            const VideoPlayer = videoInfo.type === 'vimeo' ? VimeoPlayer : YouTubePlayer;
            elements.push(
              <VideoPlayer 
                key={key++} 
                videoId={videoInfo.id} 
                title={linkText}
                originalUrl={linkUrl}
              />
            );
          } else {
            const isExternal = /^(https?:\/\/|\/\/)/.test(linkUrl);
            elements.push(
              <MuiLink
                key={key++}
                component={isExternal ? 'a' : Link}
                href={isExternal ? linkUrl : undefined}
                to={!isExternal ? linkUrl : undefined}
                sx={{
                  color: 'primary.main',
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' },
                }}
                target={isExternal ? '_blank' : undefined}
                rel={isExternal ? 'noopener noreferrer' : undefined}
              >
                {linkText}
              </MuiLink>
            );
          }
          break;
        }
      }

      pos += earliestIndex + earliestMatch[0].length;
    }

    pushCurrentText();
    return elements;
  };

  const renderMarkdown = () => {
    const lines = markdown.split('\n');
    const elements = [];
    
    let currentList = null;
    let currentCodeBlock = [];
    let currentTable = [];
    let inBlockquote = false;
    
    const handleListEnd = () => {
      if (currentList) {
        elements.push(currentList);
        currentList = null;
      }
    };

    const handleCodeBlockEnd = () => {
      if (currentCodeBlock.length > 0) {
        const language = currentCodeBlock[0] || 'text';
        currentCodeBlock.shift();
        
        elements.push(
          <Box
            key={elements.length}
            component="pre"
            sx={{
              backgroundColor: 'grey.100',
              p: 2,
              borderRadius: 1,
              overflow: 'auto',
              mt: 2,
              mb: 4,
              '& code': {
                fontFamily: 'Consolas, Monaco, monospace',
                fontSize: '0.875rem',
              }
            }}
          >
            <Box component="code" className={`language-${language}`}>
              {currentCodeBlock.join('\n')}
            </Box>
          </Box>
        );
        currentCodeBlock = [];
      }
    };

    lines.forEach((line, index) => {
      if (line.trim().startsWith('```')) {
        handleListEnd();
        if (currentCodeBlock.length === 0) {
          currentCodeBlock.push(line.trim().slice(3));
        } else {
          handleCodeBlockEnd();
        }
        return;
      }

      if (currentCodeBlock.length > 0) {
        currentCodeBlock.push(line);
        return;
      }

      if (line.trim().startsWith('#')) {
        handleListEnd();
        const level = line.match(/^#+/)[0].length;
        const content = line.slice(level).trim();
        
        elements.push(
          <Typography
            key={index}
            variant={`h${Math.min(level, 6)}`}
            component={`h${level}`}
            sx={{
              fontSize: {
                xs: `${2.2 - (level * 0.2)}rem`,
                sm: `${2.4 - (level * 0.2)}rem`
              },
              lineHeight: 1.3,
              fontWeight: 600,
              mt: level === 1 ? 2 : 4,
              mb: level === 1 ? 3 : 2,
              color: 'text.primary',
              '&:first-of-type': {
                mt: 0
              }
            }}
          >
            {parseInlineElements(content)}
          </Typography>
        );
      } else if (line.trim().startsWith('- ')) {
        const content = line.slice(2).trim();
        const listItem = (
          <ListItem key={index}>
            <ListItemText>
              {parseInlineElements(content)}
            </ListItemText>
          </ListItem>
        );

        if (!currentList) {
          currentList = <List key={index}>{[listItem]}</List>;
        } else {
          currentList = React.cloneElement(currentList, {
            children: [...currentList.props.children, listItem]
          });
        }
      } else if (line.trim() === '') {
        handleListEnd();
      } else if (line.match(/^[-*_]{3,}$/)) {
        handleListEnd();
        elements.push(<Divider key={index} sx={{ my: 3 }} />);
      } else if (line.trim()) {
        handleListEnd();
        elements.push(
          <Typography key={index} paragraph>
            {parseInlineElements(line)}
          </Typography>
        );
      }
    });

    handleListEnd();
    handleCodeBlockEnd();

    return elements;
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      {renderMarkdown()}
    </Box>
  );
};

export default MarkdownRenderer;