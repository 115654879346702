import React, { useState } from "react";
import { Typography, Divider, Box, Container, useTheme } from "@mui/material";
import Navbar from "./Navbar";
import Footer from "./Footer";

const PageLayout = ({ children, pageTitle, sections, navbarTransparent = false }) => { 
  const [loaded, setLoaded] = useState(false);
  const theme = useTheme();

  const handleOnLoad = () => {
    setLoaded(true);
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    backgroundColor: !loaded ? theme.palette.background.default : "transparent",
    opacity: loaded ? 1 : 0,
    transition: "opacity 1s ease, background-color 1s ease",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: theme.palette.background.default,
        overflowX: "hidden",
      }}
    >
      <Navbar transparent={navbarTransparent} />

      <Box
        sx={{
          flex: "1 1 auto",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          marginTop: "64px",
        }}
      >
        {children}

        {sections && (
          <Container
            id="main-content"
            maxWidth="lg"
            sx={{
              pt: { xs: 8, sm: 9, md: 3 },
              pb: { xs: 2, md: 3 },
              px: { xs: 2, md: 3 },
            }}
          >
            {pageTitle && (
              <Typography
                variant="h1"
                sx={{
                  color: theme.palette.text.primary,
                  textAlign: "center",
                  paddingBottom: "10px",
                  paddingTop: { xs: 2, sm: 4},
                  fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
                  fontWeight: "bold",
                  letterSpacing: "3px",
                }}
              >
                {pageTitle}
              </Typography>
            )}
            {sections.map((section, index) => (
              <Box key={index} sx={{ py: { xs: 5, sm: 10, md: 10 } }}>
                {index !== 0 && (
                  <Divider sx={{ backgroundColor: theme.palette.divider, my: 6 }} />
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column-reverse",
                      sm: section.image
                        ? index % 2 === 0
                          ? "row"
                          : "row-reverse"
                        : "column",
                    },
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      flex: 1,
                      p: "30px",
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{ color: theme.palette.text.primary, paddingBottom: "10px" }}
                    >
                      {section.title}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}
                    >
                      {section.subheader}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ color: theme.palette.text.secondary, paddingTop: "10px" }}
                    >
                      {section.content}
                    </Typography>
                  </Box>

                  {section.image && (
                    <Box
                      sx={{
                        flex: 1,
                        position: "relative",
                        zIndex: 1,
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: { xs: "40%", sm: "0" },
                          bottom: { xs: "30%", sm: "0" },
                          display: "block",
                        },
                      }}
                    >
                      <img
                        src={section.image}
                        alt="Section"
                        style={imageStyle}
                        onLoad={handleOnLoad}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </Container>
        )}
      </Box>

      <Footer
        sx={{
          flexShrink: 0,
          width: "100%",
        }}
      />
    </Box>
  );
};

// PageLayout.propTypes = {
//   children: PropTypes.node.isRequired,
//   pageTitle: PropTypes.string,
//   sections: PropTypes.arrayOf(PropTypes.object),
//   navbarTransparent: PropTypes.bool,
// };

export default PageLayout;