import React from 'react';
import PageLayout from '../components/PageLayout';

const privacyContent = `
PRIVACY POLICY

Last updated: October 30, 2024

Welcome to EarPhysics.com (the "Site"), owned and operated by Ear Physics. We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share your information when you use our Site.

INFORMATION WE COLLECT

We collect information you provide directly to us, such as when you create an account, place an order, or contact customer service. This information may include your name, contact details, shipping address, and payment information.

We also automatically collect certain information about your device and how you use our Site. This may include your IP address, browser type, operating system, and pages visited.

We use cookies and similar technologies to improve your browsing experience, analyze site traffic, and personalize content.

HOW WE USE YOUR INFORMATION

We use your information to:

Provide and improve our services
Process your orders and provide customer support
Communicate with you about your account or orders
Personalize your experience
Analyze site usage and trends
Send you marketing communications (with your consent)

SHARING YOUR INFORMATION

We may share your information with:

Service providers who assist us with payment processing, order fulfillment, and other essential functions
Business partners who offer products or services that may be of interest to you (with your consent)
Legal authorities if required by law

DATA SECURITY

We take reasonable measures to protect your information, but no method of transmission over the internet is completely secure.

YOUR CHOICES

You can manage your cookie preferences through your browser settings.
You can unsubscribe from marketing emails by clicking the "unsubscribe" link.

CHILDREN'S PRIVACY

Our Site is not intended for children under 13.

CHANGES TO THIS PRIVACY POLICY

We may update this policy from time to time.

CONTACT US

If you have any questions about this Privacy Policy, please visit our Contact Us page.
`;

const PrivacyPage = () => {
  const sections = [
    {
      title: 'User Data and Privacy Guidelines',
      subheader: 'Please Read Carefully',
      content: (
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {privacyContent}
        </div>
      ),
    },
  ];

  return (
    <PageLayout pageTitle="Privacy Policy" sections={sections}>
    </PageLayout>
  );
};

export default PrivacyPage;