import React, { useState } from 'react';
import { Box, Typography, Container, TextField, Grid, InputAdornment } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { articles } from './articles';
import ArticleCard from './ArticleCard';
import PageLayout from '../../components/PageLayout';

const NewsPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const filteredArticles = Object.keys(articles)
    .filter((key) =>
      articles[key].title.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .map((key) => ({
      key,
      ...articles[key],
    }));

  return (
    <PageLayout pageTitle="Hearing Innovation News">
      <Container maxWidth="lg" sx={{ mt: 8 }}>
        <Typography variant="h3" sx={{ fontWeight: 700, mb: 4 }}>
          Hearing Innovation News
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search articles..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ mb: 4 }}
        />
        <Grid container spacing={4}>
          {filteredArticles.map((article) => (
            <Grid item xs={12} sm={6} md={4} key={article.key} sx={{mb: 4}}>
              <ArticleCard articleKey={article.key} article={article} />
            </Grid>
          ))}
        </Grid>
        {filteredArticles.length === 0 && (
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Typography variant="h6" color="text.secondary">
              No articles found.
            </Typography>
          </Box>
        )}
      </Container>
    </PageLayout>
  );
};

export default NewsPage;
