import { makeAutoObservable, action } from 'mobx';
import apiHandler from '../handlers/ApiHandler';
import { jwtDecode } from 'jwt-decode';


const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

class AuthStore {
  username = '';
  userId = null;
  email = '';
  token = null;
  isAuthenticated = false;
  errorMessage = '';
  tokenDestructionTimer = null;
  needMFA = false;
  isOTP = false;

  constructor() {
    makeAutoObservable(this, {
      setToken: action,
      setUsername: action,
      setUserId: action,
      setEmail: action,
      setErrorMessage: action,
      setIsAuthenticated: action,
      setIsOTP: action,
      setNeedMFA: action,
      logout: action,
      completeLogin: action,
    });
    this.initializeAuthState();
  }

  async initializeAuthState() {
    const storedToken = window.localStorage.getItem('token');
    if (storedToken && this.isTokenValid(storedToken)) {
      this.setToken(storedToken);
      this.userId = window.localStorage.getItem('userId');
      this.isAuthenticated = true;
    } else {
      this.logout();
    }
  }

  setUsername(username) {
    this.username = username;
  }

  setUserId(userId) {
    this.userId = userId;
    window.localStorage.setItem('userId', userId);
  }

  setEmail(email) {
    this.email = email;
  }

  setIsOTP(value) {
    this.isOTP = value;
  }

  setNeedMFA(value) {
    this.needMFA = value;
  }

  setErrorMessage(message) {
    this.errorMessage = message;
  }

  setIsAuthenticated(authStatus) {
    this.isAuthenticated = authStatus;
    if (!authStatus) {
      this.logout();
    }
  }

  setToken(tokenValue) {
    if (typeof tokenValue === 'string') {
      this.token = tokenValue;
      window.localStorage.setItem('token', tokenValue);
      if (this.tokenDestructionTimer) {
        clearTimeout(this.tokenDestructionTimer);
      }
      const decoded = jwtDecode(tokenValue);
      const expiresIn = decoded.exp * 1000 - Date.now();
      this.tokenDestructionTimer = setTimeout(() => this.logout(), expiresIn);
    } else {
      this.token = null;
      window.localStorage.removeItem('token');
    }
  }

  async createAccount(username, email, password) {
    try {
      const response = await apiHandler.post('auth/signup', { username, email, password });
      if (response.token) {
        this.completeLogin(response);
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error occurred while creating account:', error);
      this.setErrorMessage(error.message || 'Account creation failed');
      this.isAuthenticated = false;
      throw error;
    }
  }

  async login(identifier, password) {
    try {
      const payload = emailRegex.test(identifier) ? { email: identifier, password } : { username: identifier, password };
      const response = await apiHandler.post('auth/signin', payload);
      if (response.needMFA) {
        this.setUserId(response.id);
        this.setNeedMFA(true);
        this.setIsOTP(false);
        this.isAuthenticated = false;
      } else if (response.token) {
        this.completeLogin(response);
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error occurred while logging in:', error);
      this.setErrorMessage(error.message || 'Login failed');
      this.setIsAuthenticated(false);
      this.setNeedMFA(false);
      throw error;
    }
  }

  async sendMFAEmail() {
    try {
      await apiHandler.post('/auth/mfa/email', { userId: this.userId });
    } catch (error) {
      throw new Error('Failed to send MFA email');
    }
  }

  async resendVerificationEmail(email) {
    try {
      await apiHandler.post('/auth/send-verification-email', { email });
      return;
    } catch (error) {
      throw new Error('Failed to send Verification email');
    }
  }

  async verifyEmail(token) {
    try {
      await apiHandler.post(`auth/verify-email?token=${token}`);
    } catch (error) {
      throw error;
    }
  }

  async verifyMFA(code) {
    try {
      const response = await apiHandler.post('/auth/mfa/verify', { userId: this.userId, code });
      if (response.token && response.username && response.email) {
        this.completeLogin(response);
      }
      return response;
    } catch (error) {
      throw new Error('MFA verification failed');
    }
  }

  completeLogin(data) {
    this.setToken(data.token);
    this.setUserId(data.id);
    this.setUsername(data.username);
    this.setEmail(data.email);
    this.setIsAuthenticated(true);
    this.setIsOTP(false);
    this.setNeedMFA(false);
  }

  logout() {
    this.setUsername('');
    this.setEmail('');
    this.setToken(null);
    this.setUserId(null);
    this.isAuthenticated = false;
    this.setIsOTP(false);
    this.setNeedMFA(false);
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('userId');
    if (this.tokenDestructionTimer) {
      clearTimeout(this.tokenDestructionTimer);
    }
  }

  isTokenValid(token) {
    try {
      if (typeof token !== 'string') return false;
      const decoded = jwtDecode(token);
      return decoded.exp > Date.now() / 1000;
    } catch (error) {
      return false;
    }
  }
}

const authStore = new AuthStore();
export default authStore;
