import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { List, ListItem, ListItemText, Divider, Typography, Box, Avatar, ListItemAvatar, Button, Container, CssBaseline } from '@mui/material';
import { Link } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import AccountSidebar from '../components/AccountSidebar';
import orderStore from '../stores/OrderStore';
import { useTheme } from '@mui/material/styles';

const OrderHistoryPage = observer(() => {
  const [orders, setOrders] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const fetchOrders = async () => {
      const userOrders = await orderStore.getUserOrders();
      setOrders(userOrders);
    };
    fetchOrders();
  }, []);

  return (
    <PageLayout pageTitle="Order History">
      <Box sx={{ display: 'flex', mt: 4, bgcolor: theme.palette.background.default }}>
        <AccountSidebar />
        <Container sx={{ m: 4, flex: 1 }}>
          <Typography variant="h4" gutterBottom>
            Order History
          </Typography>
          <List>
            {orders.length ? (
              orders.map((order) => (
                <React.Fragment key={order._id}>
                  <ListItem alignItems="flex-start" sx={{ backgroundColor: theme.palette.background.paper, borderRadius: 2, mb: 2, p: 2, boxShadow: 1 }}>
                    <Box display="flex" flexDirection="column" width="100%" gap={1}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
                        Order #{order.orderNumber} - {order.status}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Placed on: {new Date(order.createdAt).toLocaleDateString()}
                      </Typography>
                      <ListItemText primary={`Subtotal: ${order.subtotal || order.subtotal === 0 ? order.subtotal.toFixed(2) : 'N/A'} ${order.currency}`} />
                      <ListItemText primary={`Shipping And Handling: ${order.shippingAndHandling || order.shippingAndHandling === 0 ? order.shippingAndHandling.toFixed(2) : 'N/A'} ${order.currency}`} />
                      <ListItemText primary={`Taxes: ${order.taxes || order.taxes === 0 ? order.taxes.toFixed(2) : 'N/A'} ${order.currency}`} />
                      <ListItemText primary={`Total: ${order.totalAmount || order.totalAmount === 0 ? order.totalAmount.toFixed(2) : 'N/A'} ${order.currency}`} />
                      <ListItemText primary="Description" secondary={order.description || "No description available"} />
                      <ListItemText primary="Tracking Number" secondary={order.trackingNumber || "Not available"} />
                      {order.shippingAddress && (
                        <ListItemText
                          primary="Shipping Address"
                          secondary={
                            <>
                              {order.shippingAddress.businessName && (
                                <Typography>{order.shippingAddress.businessName}</Typography>
                              )}
                              <Typography>
                                {order.shippingAddress.street}
                                {order.shippingAddress.line2 && `, ${order.shippingAddress.line2}`}
                              </Typography>
                              <Typography>
                                {order.shippingAddress.city}, {order.shippingAddress.state}{" "}
                                {order.shippingAddress.postalCode}
                              </Typography>
                              {order.shippingAddress.country && (
                                <Typography>{order.shippingAddress.country}</Typography>
                              )}
                            </>
                          }
                        />
                      )}
                      <ListItemText primary="Shipping Status" secondary={order.shippingStatus || "Not available"} />
                      {order.downloadLinks && order.downloadLinks.length > 0 && (
                        <Box mt={1}>
                          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            Download Links:
                          </Typography>
                          <ul>
                            {order.downloadLinks.map((link, index) => (
                              <li key={index}>
                                <a href={link} target="_blank" rel="noopener noreferrer">
                                  {link}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </Box>
                      )}
                      <Box mt={2}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          Items:
                        </Typography>
                        <List disablePadding>
                          {order.items.map((item, index) => (
                            <ListItem key={index} sx={{ pl: 2 }}>
                              <ListItemAvatar>
                                <Avatar variant="square" src={item.productImage} alt={item.name} sx={{ width: 50, height: 50, mr: 2 }} />
                              </ListItemAvatar>
                              <ListItemText primary={item.name} secondary={`Quantity: ${item.quantity} | Price: ${item.price} ${order.currency}`} />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                      <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button
                          component={Link}
                          to={`/orders/${order._id}`}
                          variant="outlined"
                          sx={{
                            mt: 2,
                            color: theme.palette.text.primary,
                            borderColor: theme.palette.text.primary,
                            '&:hover': {
                              borderColor: theme.palette.text.primary,
                              backgroundColor: theme.palette.action.hover,
                            },
                          }}
                        >
                          View Order Details
                        </Button>
                      </Box>
                    </Box>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))
            ) : (
              <Typography>No orders found.</Typography>
            )}
          </List>
        </Container>
      </Box>
    </PageLayout>
  );
});

export default OrderHistoryPage;
