import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Box, Chip } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


const ProductCard = ({ product }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();


  const images = [
    ...(product.primaryImage ? [product.primaryImage] : []),
    ...(product.secondaryImages || []),
  ];

  const placeholderSvg = (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
      style={{ backgroundColor: '#f0f0f0' }}
    >
      <rect width="100%" height="100%" fill="#e0e0e0" />
      <text
        x="50%"
        y="50%"
        fill="#aaaaaa"
        fontSize="20"
        fontWeight="bold"
        textAnchor="middle"
        alignmentBaseline="middle"
      >
        Loading...
      </text>
    </svg>
  );

  if (images.length === 0) {
    images.push({ url: 'data:image/svg+xml;base64,' + btoa(placeholderSvg) });
  }

  const handleNextImage = (event) => {
    event.stopPropagation();
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  const handlePrevImage = (event) => {
    event.stopPropagation();
    setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
  };

  const displayName = product.name.replace(/EarPhysics/gi, '').trim();

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const renderStockStatus = () => {
    if (product.productType.includes('pre-order')) return 'Preorder';
    if (product.stock > 0) return 'In Stock';
    if (product.allowBackorders) return 'Backorder';
    if (product.stock === 0) return 'Coming Soon';
    if (product.productType === 'digital') return 'Digital';
    if (product.productType === 'made-to-order') return 'Made to Order';
    return 'Unavailable';
  };

  const stockStatus = renderStockStatus();

  return (
    <Card
        onClick={() => navigate(`/products/${product._id}`)}
        sx={{
          cursor: 'pointer',
          boxShadow: 'none',
          transition: 'box-shadow 0.3s ease-in-out',
          '&:hover': {
            boxShadow: 15,
          },
          bgcolor: 'background.paper',
        }}
      >
      <Box
        sx={{ position: 'relative', width: '100%', overflow: 'hidden', aspectRatio: '1 / 1' }}
      >
        <LazyLoadImage
          alt={displayName}
          src={images[currentImageIndex]?.url || ''}
          placeholderSrc={`data:image/svg+xml;base64,${btoa(placeholderSvg)}`}
          afterLoad={handleImageLoad}
          effect="opacity"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'opacity 0.5s ease-in-out',
            opacity: imageLoaded ? 1 : 0,
            filter: 'contrast(.95) brightness(1.02)',
          }}
        />
        {images.length > 1 && (
          <>
            <IconButton
              onClick={handlePrevImage}
              sx={{
                position: 'absolute',
                top: '50%',
                left: 0,
                transform: 'translateY(-50%)',
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
              size="small"
            >
              <ArrowBackIos fontSize="small" />
            </IconButton>
            <IconButton
              onClick={handleNextImage}
              sx={{
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translateY(-50%)',
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
              size="small"
            >
              <ArrowForwardIos fontSize="small" />
            </IconButton>
          </>
        )}
      </Box>
      <CardContent sx={{ textAlign: 'center', p: 2 }}>
        <Typography variant="subtitle1" sx={{ mb: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {displayName}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ maxHeight: '3em', overflow: 'hidden', textOverflow: 'ellipsis', mb: 1 }}>
          {product.description}
        </Typography>
        <Box component="div" sx={{ mb: 1 }}>
          {product.originalPrice && product.originalPrice > product.price ? (
            <>
              <Typography
                variant="h6"
                component="span"
                color="text.primary"
                sx={{ textDecoration: 'line-through', mr: '0.5rem' }}
              >
                ${product.originalPrice}
              </Typography>
              <Typography variant="h6" component="span" color="text.primary" sx={{ fontWeight: 'bold' }}>
                ${product.price}
              </Typography>
            </>
          ) : (
            <Typography variant="h6" component="span" color="text.primary" sx={{ fontWeight: 'bold' }}>
              ${product.price}
            </Typography>
          )}
        </Box>
        <Chip
          label={stockStatus}
          color={
            stockStatus === 'Coming Soon'
              ? 'warning'
              : stockStatus === 'Preorder' || stockStatus === 'Backorder'
                ? 'warning'
                : stockStatus === 'Digital' || stockStatus === 'Made to Order'
                  ? 'info'
                  : 'success'
          }
          sx={{ mt: 1 }}
        />
      </CardContent>
    </Card>
  );
};

export default ProductCard;
