import React from "react";
import { Container, Typography, Link, Box, useTheme } from "@mui/material";

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        pt: 6,
        pb: 3,
      }}
    >
      <Container>
        <Typography
          variant="body1"
          align="center"
          sx={{ mb: 3, fontSize: { xs: "1rem", sm: "1.25rem" } }}
        >
          © {new Date().getFullYear()} Ear Physics - Manufactured with
          <Box
            component="span"
            sx={{ color: "red", mx: 0.5 }} // Red heart
          >
            &#10084;
          </Box>
          in Michigan
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-evenly',
            alignItems: 'center',
            mb: 3,
          }}
        >
          <Typography color="inherit" sx={{ my: { xs: 1, sm: 0 } }}>
            <Link href="/" color="inherit" underline="hover">Home</Link>
          </Typography>
          <Typography color="inherit" sx={{ my: { xs: 1, sm: 0 } }}>
            <Link href="/products" color="inherit" underline="hover">Products</Link>
          </Typography>
          <Typography color="inherit" sx={{ my: { xs: 1, sm: 0 } }}>
            <Link href="/news" color="inherit" underline="hover">News</Link>
          </Typography>
          <Typography color="inherit" sx={{ my: { xs: 1, sm: 0 } }}>
            <Link href="/contact" color="inherit" underline="hover">Contact</Link>
          </Typography>
        </Box>

        <Typography
          variant="caption"
          display="block"
          align="center"
          sx={{ fontSize: '0.875rem', color: theme.palette.text.secondary }}
        >
          For more information, please review our
          <Link href="/privacy" underline="hover" color="inherit" sx={{ mx: 0.5 }}>
            Privacy Policy
          </Link>
          and
          <Link href="/terms" underline="hover" color="inherit" sx={{ mx: 0.5 }}>
            Terms & Conditions.
          </Link>
          <Link
            href="https://patents.google.com/patent/US20200177147A1/en"
            underline="hover"
            color="inherit"
            rel="noopener noreferrer"
            target="_blank"
          >
            US Patent 11,641,183
          </Link>
          ,&nbsp;
          <Link
            href="https://patents.google.com/patent/US12101605B2/en"
            underline="hover"
            color="inherit"
            rel="noopener noreferrer"
            target="_blank"
          >
            US Patent 12,101,605
          </Link>
          , other patents pending.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
