import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { articles } from './articles';
import MarkdownRenderer from './MarkdownRenderer';
import { 
  Box, 
  Typography, 
  Container,
  Breadcrumbs, 
  Button,
  CircularProgress,
  Paper,
  Fade,
  useTheme,
  useScrollTrigger,
  IconButton
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ShareIcon from '@mui/icons-material/Share';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PageLayout from '../../components/PageLayout';

const ScrollProgress = ({ target }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (!target.current) return;

      const element = target.current;
      const windowHeight = window.innerHeight;
      const documentHeight = element.offsetHeight;
      const scrollTop = window.scrollY;
      
      const scrollDistance = documentHeight - windowHeight;
      const progress = (scrollTop / scrollDistance) * 100;
      
      setProgress(Math.min(100, Math.max(0, progress)));
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [target]);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        height: 3,
        zIndex: 1000,
        bgcolor: 'grey.200'
      }}
    >
      <Box
        sx={{
          height: '100%',
          bgcolor: 'primary.main',
          width: `${progress}%`,
          transition: 'width 0.1s ease-out'
        }}
      />
    </Box>
  );
};

const ScrollToTop = () => {
  const trigger = useScrollTrigger({
    threshold: 100,
    disableHysteresis: true
  });

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Fade in={trigger}>
      <IconButton
        onClick={handleClick}
        sx={{
          position: 'fixed',
          bottom: 24,
          right: 24,
          bgcolor: 'primary.main',
          color: 'white',
          '&:hover': {
            bgcolor: 'primary.dark',
          },
          zIndex: 1000,
        }}
      >
        <KeyboardArrowUpIcon />
      </IconButton>
    </Fade>
  );
};

const Article = () => {
  const { article } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const selectedArticle = articles[article];
  const [markdownContent, setMarkdownContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const articleRef = useRef(null);
  const headerRef = useRef(null);
  const [imageHeight, setImageHeight] = useState(500);
  const [headerOpacity, setHeaderOpacity] = useState(1);

  useEffect(() => {
    const fetchMarkdown = async () => {
      setIsLoading(true);
      setError(null);

      try {
        if (selectedArticle?.content) {
          const response = await fetch(selectedArticle.content);
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
          const content = await response.text();
          setMarkdownContent(content);
        } else {
          throw new Error("Article content URL not found.");
        }
      } catch (error) {
        console.error('Error fetching Markdown:', error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMarkdown();
  }, [selectedArticle]);

  useEffect(() => {
    const handleScroll = () => {
      if (!headerRef.current) return;
      
      const scrollPosition = window.scrollY;
      const maxScroll = 400;
      
      setImageHeight(Math.max(300, 500 - scrollPosition * 0.5));
    
      setHeaderOpacity(Math.max(0, 1 - scrollPosition / maxScroll));
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!selectedArticle) {
    return (
      <PageLayout pageTitle="Article Not Found">
        <Container maxWidth="md" sx={{ mt: 8 }}>
          <Typography variant="h6">
            The article you are looking for does not exist.
          </Typography>
          <Button 
            variant="outlined" 
            onClick={() => navigate('/news')} 
            sx={{ mt: 2 }}
          >
            Go to News
          </Button>
        </Container>
      </PageLayout>
    );
  }

  return (
    <PageLayout pageTitle={selectedArticle.title}>
      <ScrollProgress target={articleRef} />
      <ScrollToTop />
      
      <Box sx={{ position: 'relative' }}>
        <Box
          ref={headerRef}
          sx={{
            position: 'relative',
            height: `${imageHeight}px`,
            overflow: 'hidden',
            transition: 'height 0.1s ease-out',
          }}
        >
          <Box
            component="img"
            src={selectedArticle.image}
            alt={selectedArticle.title}
            sx={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
         <Box
            sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                background: 'linear-gradient(to bottom, transparent, rgba(0,0,0,0.8))',
                pt: 8,
                pb: 4,
                px: 3,
                opacity: headerOpacity,
                transition: 'opacity 0.1s ease-out',
            }}
            >
            <Container maxWidth="md">
            <Typography
                variant="h2"
                color="white"
                sx={{
                    mb: 2,
                    fontWeight: 700,
                    filter: `blur(${(1 - headerOpacity) * 10}px)`,
                    opacity: headerOpacity,
                    transition: 'all 0.1s ease-out',
                    fontSize: {
                    [theme.breakpoints.down('sm')]: '2.5rem',
                    [theme.breakpoints.up('md')]: '3.75rem',
                    },
                }}
                >
                {selectedArticle.title}
                </Typography>
                <Box 
                sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 2, 
                    color: 'white',
                    transition: 'transform 0.1s ease-out',
                    transform: `translateY(${(1 - headerOpacity) * 10}px)`,
                }}
                >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CalendarTodayIcon sx={{ fontSize: 16 }} />
                    <Typography variant="body2">
                    {new Date(selectedArticle.date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })}
                    </Typography>
                </Box>
                <IconButton 
                    size="small" 
                    sx={{ 
                    color: 'white',
                    opacity: headerOpacity, // Fade out button separately
                    transition: 'opacity 0.1s ease-out',
                    }}
                    onClick={() => navigator.share?.({
                    title: selectedArticle.title,
                    url: window.location.href
                    })}
                >
                    <ShareIcon sx={{ fontSize: 16 }} />
                </IconButton>
                </Box>
            </Container>
            </Box>
        </Box>

        <Container 
          maxWidth="md" 
          sx={{ 
            position: 'relative',
            mt: -8,
            zIndex: 1,
          }}
        >
          <Paper
            elevation={24}
            sx={{
              position: 'relative',
              p: { xs: 2, sm: 4, md: 6 },
              borderRadius: 1,
              bgcolor: 'background.paper',
            }}
          >
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                <Button
                    variant="outlined"
                    onClick={() => navigate('/news')}
                    sx={{
                    ml:-2,
                    border: '1px solid transparent',
                    transition: 'border-color 0.3s ease',
                    '&:hover': {
                        borderColor: 'inherit',
                        backgroundColor: 'transparent',
                    },
                    }}
                >
                    News
                </Button>
                <Typography color="text.primary">{selectedArticle.title}</Typography>
            </Breadcrumbs>

            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 8 }}>
                <CircularProgress />
              </Box>
            ) : error ? (
              <Box sx={{ my: 4, color: 'error.main' }}>
                <Typography variant="body1">
                  Error loading article: {error.message}
                </Typography>
              </Box>
            ) : (
              <Box ref={articleRef}>
                <MarkdownRenderer markdown={markdownContent} />
              </Box>
            )}
          </Paper>
        </Container>
      </Box>
    </PageLayout>
  );
};

export default Article;