import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Container,
  IconButton,
  FormHelperText,
  Stepper,
  Step,
  StepLabel,
  Snackbar,
  Alert,
  useTheme,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { observer } from 'mobx-react-lite';
import Logo from '../assets/images/full-logo-white.png';

import authStore from '../stores/AuthStore';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

const steps = ['Personal Info', 'Account Info', 'Confirmation'];

const SignUpPage = observer(() => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    phone: '',
    date: '',
    password: '',
    confirmPassword: '',
    allowExtraEmails: false,
    agreeToTerms: false,
  });

  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState({});
  const [submissionError, setSubmissionError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const redirectUrl = new URLSearchParams(location.search).get('redirectUrl') || '/products';

  useEffect(() => {
    if (authStore.isAuthenticated) {
      navigate('/audio-response-evaluation');
    }
  }, [authStore.isAuthenticated, navigate]);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: value.startsWith('+1') ? value.slice(2) : value,
    }));
  };

  const validate = () => {
    const validationErrors = {};
    if (activeStep === 0) {
      if (!formData.firstName) validationErrors.firstName = 'First Name is required';
      if (!formData.lastName) validationErrors.lastName = 'Last Name is required';
      if (!formData.username) validationErrors.username = 'Username is required';
      if (emailRegex.test(formData.username)) validationErrors.username = 'A username cannot be an email address';
    } else if (activeStep === 1) {
      if (!formData.email || !emailRegex.test(formData.email)) validationErrors.email = 'A valid Email is required';
      if (formData.phone && !parsePhoneNumberFromString(formData.phone, 'US')?.isValid()) validationErrors.phone = 'Invalid phone number';
      if (!formData.date) validationErrors.date = 'Date of Birth is required';
    } else if (activeStep === 2) {
      if (!formData.password || !passwordRegex.test(formData.password)) validationErrors.password = 'Password must be at least 8 characters long and contain a number, an uppercase letter, and a special character';
      if (formData.password !== formData.confirmPassword) validationErrors.confirmPassword = 'Passwords do not match';
      if (!formData.agreeToTerms) validationErrors.agreeToTerms = 'You must agree to the terms';
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleNext = async () => {
    if (validate()) {
      if (activeStep === steps.length - 1) {
        try {
          await authStore.createAccount(formData.username, formData.email, formData.password);
          navigate(redirectUrl);
        } catch (error) {
          setSubmissionError('Account creation failed, please check Username/Email');
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await handleNext();
  };

  return (
    <Box component="main" sx={{
      backgroundImage: `url(${require('../assets/images/physics.png')})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      overflowX: 'hidden',
    }}>
       <Container maxWidth="sm" sx={{ 
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
      }}>
        <Box 
           sx={{m: 2}}
        >
          <img
              src={Logo}
              alt="Ear Physics Logo"
              style={{
                width: '200px',
                height: 'auto',
              }}
            />
        </Box>
        <Box sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          backdropFilter: 'blur(5px)',
          borderRadius: 2,
          p: 4,
          width: '100%',
          maxWidth: 'sm',
        }}>
          <Box sx={{ mb: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <IconButton color="inherit" component={Link} to="/">
              <ArrowBackIcon />
            </IconButton>
            <Typography component="h1" variant="h5" gutterBottom sx={{ color: theme.palette.text.primary, flex: 1, textAlign: 'center', mr: 4 }}>
              Let's get some information first!
            </Typography>
          </Box>
          <Typography component="p" variant="body1" align="center" sx={{ mb: 2, color: theme.palette.text.secondary }}>
            Please fill out the form below to proceed.
          </Typography>
          <Stepper activeStep={activeStep} sx={{ width: '100%', mb: 4 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ width: '100%' }}>
            {activeStep === 0 && (
              <>
                <Typography variant="body2" sx={{ color: theme.palette.text.primary, mb: 1 }}>
                  First Name
                </Typography>
                <TextField
                  placeholder="Enter your first name"
                  margin="dense"
                  required
                  fullWidth
                  id="firstName"
                  name="firstName"
                  autoComplete="fname"
                  autoFocus
                  value={formData.firstName}
                  onChange={handleChange}
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName}
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                    },
                  }}
                />
                <Typography variant="body2" sx={{ color: theme.palette.text.primary, mt: 2 }}>
                  Last Name
                </Typography>
                <TextField
                  placeholder="Enter your last name"
                  margin="dense"
                  required
                  fullWidth
                  id="lastName"
                  name="lastName"
                  autoComplete="lname"
                  value={formData.lastName}
                  onChange={handleChange}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName}
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                    },
                  }}
                />
                <Typography variant="body2" sx={{ color: theme.palette.text.primary, mt: 2 }}>
                  Username
                </Typography>
                <TextField
                  placeholder="Enter your username"
                  margin="dense"
                  required
                  fullWidth
                  id="username"
                  name="username"
                  autoComplete="username"
                  value={formData.username}
                  onChange={handleChange}
                  error={Boolean(errors.username)}
                  helperText={errors.username}
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                    },
                  }}
                />
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleNext}
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  }}
                >
                  Next
                </Button>
              </>
            )}
            {activeStep === 1 && (
              <>
                <Typography variant="body2" sx={{ color: theme.palette.text.primary, mb: 1 }}>
                  Email Address
                </Typography>
                <TextField
                  placeholder="Enter your email address"
                  margin="dense"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                    },
                  }}
                />
                <Typography variant="body2" sx={{ color: theme.palette.text.primary, mt: 2 }}>
                  Phone Number
                </Typography>
                <PhoneInput
                  country={'us'}
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  inputStyle={{
                    width: '100%',
                    height: '56px',
                    fontSize: '16px',
                    padding: '10px 12px 10px 48px',
                    borderRadius: '4px',
                    borderColor: theme.palette.divider,
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.background.paper,
                  }}
                  containerStyle={{ width: '100%', marginBottom: '16px' }}
                  buttonStyle={{
                    borderTopLeftRadius: '4px',
                    borderBottomLeftRadius: '4px',
                    borderColor: theme.palette.divider,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  }}
                />
                {errors.phone && <FormHelperText error>{errors.phone}</FormHelperText>}
                <Typography variant="body2" sx={{ color: theme.palette.text.primary, mt: 2 }}>
                  Date of Birth
                </Typography>
                <TextField
                  placeholder="Select your date of birth"
                  margin="dense"
                  required
                  fullWidth
                  id="date"
                  name="date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                    },
                  }}
                  value={formData.date}
                  onChange={handleChange}
                  error={Boolean(errors.date)}
                  helperText={errors.date}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="outlined"
                    onClick={handleBack}
                    sx={{
                      mt: 3,
                      mb: 2,
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.dark,
                      },
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleNext}
                    sx={{
                      mt: 3,
                      mb: 2,
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.dark,
                      },
                    }}
                  >
                    Next
                  </Button>
                </Box>
              </>
            )}
            {activeStep === 2 && (
              <>
                {submissionError && (
                  <Snackbar open={true} autoHideDuration={6000}>
                    <Alert severity="error">{submissionError}</Alert>
                  </Snackbar>
                )}
                <Typography variant="body2" sx={{ color: theme.palette.text.primary, mb: 1 }}>
                  Password
                </Typography>
                <TextField
                  placeholder="Enter a secure password"
                  margin="dense"
                  required
                  fullWidth
                  id="password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  error={Boolean(errors.password)}
                  helperText={errors.password}
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                    },
                  }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 2 }}>
                  <Typography variant="body2" sx={{ color: formData.password.length >= 8 ? theme.palette.success.main : theme.palette.error.main }}>
                    {formData.password.length >= 8 ? <CheckIcon /> : <CloseIcon />} At least 8 characters
                  </Typography>
                  <Typography variant="body2" sx={{ color: /[A-Z]/.test(formData.password) ? theme.palette.success.main : theme.palette.error.main }}>
                    {/[A-Z]/.test(formData.password) ? <CheckIcon /> : <CloseIcon />} At least one uppercase letter
                  </Typography>
                  <Typography variant="body2" sx={{ color: /[a-z]/.test(formData.password) ? theme.palette.success.main : theme.palette.error.main }}>
                    {/[a-z]/.test(formData.password) ? <CheckIcon /> : <CloseIcon />} At least one lowercase letter
                  </Typography>
                  <Typography variant="body2" sx={{ color: /[0-9]/.test(formData.password) ? theme.palette.success.main : theme.palette.error.main }}>
                    {/[0-9]/.test(formData.password) ? <CheckIcon /> : <CloseIcon />} At least one number
                  </Typography>
                  <Typography variant="body2" sx={{ color: /[@$!%*#?&]/.test(formData.password) ? theme.palette.success.main : theme.palette.error.main }}>
                    {/@$!%*#?&/.test(formData.password) ? <CheckIcon /> : <CloseIcon />} At least one special character
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ color: theme.palette.text.primary, mt: 2 }}>
                  Confirm Password
                </Typography>
                <TextField
                  placeholder="Confirm your password"
                  margin="dense"
                  required
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  error={Boolean(errors.confirmPassword)}
                  helperText={errors.confirmPassword}
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                    },
                  }}
                />
                <FormControlLabel
                  control={<Checkbox checked={formData.allowExtraEmails} color="primary" onChange={handleChange} name="allowExtraEmails" />}
                  label="I would like to receive email and text updates"
                />
                <FormControlLabel
                  control={<Checkbox checked={formData.agreeToTerms} color="primary" onChange={handleChange} name="agreeToTerms" />}
                  label="I agree to the Ear Physics Terms of Service"
                />
                {errors.agreeToTerms && <FormHelperText error>{errors.agreeToTerms}</FormHelperText>}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="outlined"
                    onClick={handleBack}
                    sx={{
                      mt: 3,
                      mb: 2,
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.dark,
                      },
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    variant="outlined"
                    sx={{
                      mt: 3,
                      mb: 2,
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.dark,
                      },
                    }}
                  >
                    Continue
                  </Button>
                </Box>
              </>
            )}
          </Box>
          <Button
            fullWidth
            variant="text"
            component={Link}
            to="/sign-in"
            sx={{
              mt: 1,
              color: theme.palette.text.primary,
            }}
          >
            Already have an account? Sign In
          </Button>
        </Box>
      </Container>
    </Box>
  );
});

export default SignUpPage;
