import axios, { CancelToken } from 'axios';

class ApiHandler {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API,
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  getToken() {
    return window.localStorage.getItem('token');
  }

  setToken(token) {
    if (typeof token === 'string') {
      window.localStorage.setItem('token', token);
    }
  }

  removeToken() {
    window.localStorage.removeItem('token');
  }

  async getHeaders() {
    const token = this.getToken();
    return {
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : undefined,
    };
  }

  handleErrorCode(error) {
    let userFriendlyMessage = 'An unexpected error occurred. Please try again later.';

    if (error.response) {
      const status = error.response.status;
      const data = error.response.data;
      const message = data && data.message ? data.message : 'An unexpected error occurred.';

      console.error(`API Error (${status}): ${message}`, data);

      switch (status) {
        case 400:
          userFriendlyMessage = 'Bad request. Please check your input and try again.';
          break;
        case 401:
          userFriendlyMessage = 'Unauthorized. Please log in again.';
          this.removeToken();
          break;
        case 403:
          if (message && /Email not verified/.test(message)) {
            userFriendlyMessage = message;
          } else if (message && /User account is not active/.test(message)) {
            userFriendlyMessage = message;
          } else {
            userFriendlyMessage = 'Forbidden. You do not have permission to perform this action.';
          }
          break;
        case 404:
          userFriendlyMessage = 'Not found. The requested resource could not be found.';
          break;
        case 500:
          userFriendlyMessage = 'Server error. Please try again later.';
          break;
        default:
          userFriendlyMessage = `Error ${status}: ${message}`;
      }
    } else if (error.request) {
      console.error('API Request Error:', error.message);
      userFriendlyMessage = 'Network error. Please check your internet connection and try again.';
    } else {
      console.error('API Error:', error.message);
      userFriendlyMessage = error.message;
    }

    return userFriendlyMessage;
  }

  async request(method, endpoint, data, successMessage) {
    try {
      const headers = await this.getHeaders();
      const cancelToken = CancelToken.source();

      const response = await this.api.request({
        method,
        url: endpoint,
        data,
        headers,
        cancelToken: cancelToken.token,
      });

      return response.data;
    } catch (error) {
      const userFriendlyMessage = this.handleErrorCode(error);
      throw new Error(userFriendlyMessage);
    }
  }

  get(endpoint) {
    return this.request('get', endpoint);
  }

  post(endpoint, data, successMessage) {
    return this.request('post', endpoint, data, successMessage);
  }

  put(endpoint, data, successMessage) {
    return this.request('put', endpoint, data, successMessage);
  }

  delete(endpoint, data, successMessage) {
    return this.request('delete', endpoint, data, successMessage);
  }
}

const apiHandler = new ApiHandler();
export default apiHandler;
