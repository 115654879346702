// src/pages/news/articles/index.js
import newEraOfListeningContent from './new-era-of-listening.md';
import michaelSweetContent from './michael-sweet.md'; 
import rickCampContent from './rick-camp.md'; 
import nucleasImg from '../../../assets/images/nucleas.png';
import michaelSweetImg from '../../../assets/images/michael-sweet.png'; 
import rickCampImg from '../../../assets/images/rick-camp.png'; 

export const articles = {
  'new-era-of-listening': {
    title: 'Harnessing Acoustic Engineering for a New Era of Listening',
    content: newEraOfListeningContent,
    image: nucleasImg,
    summary: 'Explore the latest breakthroughs in acoustic engineering with our cutting-edge IEMs.',
  },
  'michael-sweet-stryper': {
    title: 'Michael Sweet of Stryper Experiences Ear Physics',
    content: michaelSweetContent, 
    image: michaelSweetImg, 
    summary: 'Michael Sweet of Stryper shares his experience with Ear Physics in-ear monitors.',
  },
  'rick-camp-foh-engineer': {
    title: `Rick Camp: A FOH Engineer's Perspective on Ear Physics`,
    content: rickCampContent,
    image: rickCampImg, 
    summary: 'FOH engineer Rick Camp discusses the advantages of Ear Physics in-ear monitors.',
  },
};