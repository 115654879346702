import React, { useState } from 'react';
import { observer } from 'mobx-react-lite'; 
import { 
  Box, 
  TextField, 
  Grid, 
  Button, 
  Snackbar, 
  Alert, 
  Typography, 
  useTheme, 
  Container,
  CircularProgress 
} from '@mui/material';
import PageLayout from '../components/PageLayout'; 
import ReCAPTCHA from 'react-google-recaptcha'; 
import marbles from "../assets/images/00034-1130279688.png"; 
import Banner from '../components/Banner'; 
import contactStore from '../stores/ContactStore';

const ContactPage = observer(() => {
  const theme = useTheme();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const onRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    contactStore.clearError();
    contactStore.clearSuccessMessage();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!firstName || !lastName || !email || !message || !recaptchaValue) {
      contactStore.error = "Please fill all fields and complete the reCAPTCHA.";
      setSnackbarOpen(true);
      return;
    }

    try {
      await contactStore.submitContactForm({
        firstName,
        lastName,
        email,
        message
      });
      setFormSubmitted(true);
      setSnackbarOpen(true);
      setFirstName('');
      setLastName('');
      setEmail('');
      setMessage('');
      setRecaptchaValue('');
    } catch (error) {
      contactStore.error = error.message;
      setSnackbarOpen(true);
    }
  };

  const contactForm = formSubmitted ? (
    <Typography variant="h5" style={{ m: 4, color: theme.palette.text.primary, textAlign: 'center' }}>
      Thank you for contacting us. Your message has been sent successfully!
    </Typography>
  ) : (
    <form onSubmit={handleSubmit}>
      <Box padding="25px 0">
        <Grid container spacing={2} style={{ marginBottom: "40px" }}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" sx={{ color: theme.palette.text.primary, mb: 1 }}>
              First name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body2" sx={{ color: theme.palette.text.primary, mb: 1 }}>
              Last name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" sx={{ color: theme.palette.text.primary, mb: 1 }}>
              Email
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" sx={{ color: theme.palette.text.primary, mb: 1 }}>
              How can we help?
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "left" }}>
            <ReCAPTCHA
              sitekey="6LdYIyYpAAAAAKQIToeyxPR5qqCxGE4c68NZs4wc"
              onChange={onRecaptchaChange}
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "left" }}>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              sx={{
                width: { xs: '100%', md: '300px' },
              }}
              disabled={contactStore.isLoading}
            >
              {contactStore.isLoading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );

  return (
    <PageLayout pageTitle="Contact US">
      <Container component="main" sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>Contact Us</Typography>
        {contactForm}
        <Banner
          title="Adaptive Response IEMs: Hear Your Music Like Never Before"
          subtitle="Experience true sonic immersion with Adaptive Response IEMs."
          description="Unleash your full potential with personalized sound that adapts to your ears. Say goodbye to ear fatigue and hello to sonic bliss."
          buttonText="Pre-order Now and Save!"
          buttonLink="/products"
          imageSrc={marbles}
          imagePosition="right"
        />
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={contactStore.error ? 'error' : 'success'} sx={{ width: '100%' }}>
            {contactStore.error || contactStore.successMessage}
          </Alert>
        </Snackbar>
      </Container>
    </PageLayout>
  );
});

export default ContactPage;