import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    Box,
    Typography,
    Button,
    TextField,
    Container,
    Snackbar,
    Alert,
    CircularProgress,
    useTheme,
} from '@mui/material';
import authStore from '../stores/AuthStore';

const VerifyEmail = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [statusMessage, setStatusMessage] = useState('Verifying Email...');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [submissionError, setSubmissionError] = useState(null);
    const theme = useTheme();
    const token = searchParams.get('token');

    useEffect(() => {
        if (token) {
            verifyEmail();
        } else {
            setError(true);
            setStatusMessage('Invalid or missing token. Redirecting...');
            setTimeout(() => navigate('/'), 3000);
            setLoading(false);
        }
    }, [token, navigate]);

    const verifyEmail = async () => {
        setLoading(true);
        try {
            await authStore.verifyEmail(token);
            setStatusMessage('Email verified. Redirecting...');
            setTimeout(() => navigate('/'), 3000);
        } catch (error) {
            setError(true);
            setStatusMessage('Failed to verify email address.');
            setSubmissionError('Failed to verify email address.');
        } finally {
            setLoading(false);
        }
    };

    const resendVerificationEmail = async () => {
        setLoading(true);
        try {
            await authStore.resendVerificationEmail(email);
            setStatusMessage(`Verification email resent to ${email}. Check your inbox and spam folder.`);
            setError(false);
        } catch (error) {
            setStatusMessage('Failed to resend verification email. Please try again.');
            setSubmissionError('Failed to resend verification email.');
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box component="main" sx={{ 
            backgroundImage: `url(${require('../assets/images/physics.png')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '100vh',
            width: '100vw',
            maxWidth: '100vw',
            overflowX: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Container maxWidth="sm">
                <Box
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        backdropFilter: 'blur(8px)',
                        borderRadius: 2,
                        p: 4,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                        transition: 'all 0.3s ease',
                    }}
                >
                    <Typography
                        component="h1"
                        variant="h5"
                        gutterBottom
                        sx={{ color: theme.palette.text.primary }}
                    >
                        Verify Email
                    </Typography>
                    <Typography component="p" variant="body1" sx={{ mb: 2, color: theme.palette.text.secondary }}>
                        {statusMessage}
                    </Typography>
                    {submissionError && (
                        <Snackbar open={true} autoHideDuration={6000}>
                            <Alert severity="error">{submissionError}</Alert>
                        </Snackbar>
                    )}
                    {loading && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                                transition: 'opacity 0.3s ease, transform 0.3s ease',
                                opacity: loading ? 1 : 0,
                                transform: loading ? 'scale(1)' : 'scale(0.9)',
                            }}
                        >
                            <CircularProgress size={50} thickness={4.5} />
                        </Box>
                    )}
                    {!loading && error && token && (
                        <Box
                            sx={{
                                opacity: 1,
                                transform: 'translateY(0)',
                                transition: 'all 0.3s ease',
                                width: '100%',
                            }}
                        >
                            <TextField
                                sx={{ mt: 2 }}
                                label="Email"
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                                disabled={loading}
                                InputLabelProps={{
                                    style: { color: theme.palette.text.primary },
                                }}
                                InputProps={{
                                    style: {
                                        backgroundColor: theme.palette.background.paper,
                                        color: theme.palette.text.primary,
                                    },
                                }}
                            />
                            <Button
                                variant="outlined"
                                sx={{
                                    mt: 2,
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    '&:hover': {
                                        backgroundColor: `${theme.palette.primary.main}CC`,
                                    },
                                }}
                                disabled={loading || !email}
                                onClick={resendVerificationEmail}
                            >
                                Resend Verification Email
                            </Button>
                        </Box>
                    )}
                </Box>
            </Container>
        </Box>
    );
};

export default VerifyEmail;
