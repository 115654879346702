import { makeAutoObservable } from 'mobx';
import apiHandler from '../handlers/ApiHandler';
import authStore from './AuthStore';

class CheckoutStore {
  name = '';
  email = '';
  billingAddress = '';
  shippingAddress = '';
  phone = '';
  paymentStatus = 'idle';
  cartStore = null;

  constructor(cartStore) {
    this.cartStore = cartStore;
    makeAutoObservable(this);
  }

  setName(name) {
    this.name = name;
  }

  setEmail(email) {
    this.email = email;
  }

  setBillingAddress(address) {
    this.billingAddress = address;
  }

  setShippingAddress(address) {
    this.shippingAddress = address;
  }

  setPhone(phone) {
    this.phone = phone;
  }

  async createIntent(order, additionalDetails) {
    this.paymentStatus = 'processing';

    const intent = {
      order,
      additionalDetails
    }

    try {
      const response = await apiHandler.post('/payment/create', intent );
      this.paymentStatus = 'succeeded';
      return response;
    } catch (error) {
      this.paymentStatus = 'error';
      throw new Error('Payment processing failed');
    }
  }

  async submitOrder() {
    try {
      const order = {
        userId: authStore.user._id,
        status: 'pending',
        totalAmount: this.cartStore.total,
        currency: 'USD',
        orderNumber: `EP-${Date.now()}`,
        items: this.cartStore.cartItems.map(item => ({
          product: item._id,
          quantity: item.quantity,
          productPrice: item.price,
          productImage: item.productImage,
        })),
        shippingAddress: this.shippingAddress,
        billingAddress: this.billingAddress,
        phone: this.phone,
        description: `Order placed by ${this.name} - ${new Date().toLocaleString()}`,
      };

      const response = await apiHandler.post('/orders/create', { order });

      if (response.data.success) {
        this.cartStore.clearCart();
        this.paymentStatus = 'completed';
        return response.data.order;
      } else {
        throw new Error('Order creation failed');
      }
    } catch (error) {
      console.error('Order submission failed:', error);
      throw new Error('Order submission failed');
    }
  }

  loadCheckoutData() {
    this.setName(localStorage.getItem('checkout_name') || '');
    this.setEmail(localStorage.getItem('checkout_email') || '');
    this.setBillingAddress(localStorage.getItem('checkout_billingAddress') || '');
    this.setShippingAddress(localStorage.getItem('checkout_shippingAddress') || '');
    this.setPhone(localStorage.getItem('checkout_phone') || '');
  }

  saveCheckoutData() {
    try {
      localStorage.setItem('checkout_name', this.name);
      localStorage.setItem('checkout_email', this.email);
      localStorage.setItem('checkout_billingAddress', this.billingAddress);
      localStorage.setItem('checkout_shippingAddress', this.shippingAddress);
      localStorage.setItem('checkout_phone', this.phone);
    } catch (error) {
      console.error('Failed to save checkout data locally:', error);
    }
  }
}

export default CheckoutStore;
