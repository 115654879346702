import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Player from '@vimeo/player';

const VideoBanner = ({
  videoId,
  logoSrc,
  title,
  onLoaded,
  scrollTargetId,
  onScrollDown,
  aspectRatio,
  overlayColor,
}) => {
  const [videoLoading, setVideoLoading] = useState(true);
  const iframeRef = useRef(null);
  const minHeight = useMediaQuery('(max-height: 400px)');
  const maxAspect = useMediaQuery('(max-aspect-ratio: 16/9)');
  const minAspect = useMediaQuery('(min-aspect-ratio: 16/9)');

  useEffect(() => {
    let player;
    if (iframeRef.current) {
      player = new Player(iframeRef.current);

      player.on('play', () => {
        setVideoLoading(false);
      });

      player.on('loaded', () => {
        if (typeof onLoaded === 'function') {
          onLoaded();
        }
      });
    }

    return () => {
      if (player) {
        player.off('play');
        player.off('loaded');
      }
    };
  }, [onLoaded]);

  const handleScrollDown = (e) => {
    e.stopPropagation();
    const target = document.getElementById(scrollTargetId);
    if (typeof onScrollDown === 'function') {
      onScrollDown();
    } else if (target) {
      target.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const videoUrl = `https://player.vimeo.com/video/${videoId}?background=1&autoplay=1&muted=1`;

  const aspectRatioMap = {
    '16:9': 9 / 16,
    '9:16': 16 / 9,
    '1:1': 1,
  };

  const aspectRatioValue = aspectRatioMap[aspectRatio];

  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
    width: '100vw',
    marginTop: '-64px'
  };

  if (aspectRatio === 'full') {
    containerStyles.height = '100vh';
  } else if (aspectRatioValue) {
    containerStyles.height = 0;
    containerStyles.paddingBottom = `${(1 / aspectRatioValue) * 100}%`;
  } else {
    containerStyles.height = '100vh';
  }

  return (
    <Box sx={containerStyles}>
      <iframe
        title="video-banner"
        src={videoUrl}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        ref={iframeRef}
        style={{
          position: 'absolute',
          zIndex: 1,
          top: '50%',
          left: '50%',
          height: minAspect ? '56.25vw' : '100vh',
          width: maxAspect ? '177.78vh' : '100vw',
          cursor: 'pointer',
          transform: 'translate(-50%, -50%)',
        }}
      ></iframe>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'linear-gradient(180deg, rgba(50, 50, 50, 1), rgba(0, 0, 0, 1))',
          opacity: videoLoading ? 1 : 0.3,
          transition: 'opacity 0.5s ease',
          zIndex: 2,
          cursor: 'pointer',
        }}
        onClick={handleScrollDown}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          p: { xs: 2, sm: 3 },
          mt: { xs: 0, sm: 2 },
          color: 'white',
          cursor: 'pointer',
        }}
        onClick={handleScrollDown}
      >
        {logoSrc && (
          <img
            alt="Logo"
            src={logoSrc}
            width="100%"
            height="auto"
            style={{ maxWidth: 350, marginBottom: 16 }}
          />
        )}
        {title && (
          <Typography variant={minHeight ? 'h4' : 'h3'} sx={{ color: 'white', maxWidth: '85%' }}>
            {title}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: { xs: '36px', sm: '20px' },
          left: '50%',
          transform: 'translateX(-50%)',
          textAlign: 'center',
          color: 'white',
          zIndex: 3,
          '&:hover': {
            '& .MuiIconButton-root': {
              animation: 'bounce 1s infinite',
            }
          },
          '@keyframes bounce': {
            '0%, 100%': {
              transform: 'translateY(0)',
            },
            '50%': {
              transform: 'translateY(8px)',
            }
          },
          '@keyframes autoBounce': {
            '0%, 100%': {
              transform: 'translateY(0)',
              opacity: 0.8,
            },
            '50%': {
              transform: 'translateY(8px)',
              opacity: 1,
            }
          }
        }}
      >
        <Typography 
          color="white" 
          sx={{ 
            fontSize: { xs: '0.875rem', sm: '1rem' },
            mb: 0.5,
            opacity: 0.9
          }}
        >
          Learn More
        </Typography>
        <IconButton 
            onClick={handleScrollDown} 
            size="small" 
            sx={{ 
              color: 'white',
              transition: 'transform 0.2s ease',
              animation: 'autoBounce 5s ease-in-out infinite',
              '@keyframes autoBounce': {
                '0%, 40%, 100%': {
                  transform: 'translateY(0)',
                  opacity: 0.8,
                },
                '20%': {
                  transform: 'translateY(8px)',
                  opacity: 1,
                }
              },
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                animation: 'bounce 1s infinite',
              }
            }}
          >
          <ExpandMore />
        </IconButton>
      </Box>
    </Box>
  );
};

VideoBanner.propTypes = {
  videoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  logoSrc: PropTypes.string,
  title: PropTypes.string,
  onLoaded: PropTypes.func,
  scrollTargetId: PropTypes.string,
  onScrollDown: PropTypes.func,
  aspectRatio: PropTypes.oneOf(['16:9', '9:16', '1:1', 'full']),
  overlayColor: PropTypes.string,
};

VideoBanner.defaultProps = {
  logoSrc: null,
  title: '',
  onLoaded: null,
  scrollTargetId: null,
  onScrollDown: null,
  aspectRatio: 'full',
  overlayColor: 'black',
};

export default VideoBanner;
