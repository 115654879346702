import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Box } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const ArticleCard = ({ articleKey, article }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();

  const images = [article.image]; // You can add more images here if needed

  const placeholderSvg = (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
      style={{ backgroundColor: '#f0f0f0' }}
    >
      <rect width="100%" height="100%" fill="#e0e0e0" />
      <text
        x="50%"
        y="50%"
        fill="#aaaaaa"
        fontSize="20"
        fontWeight="bold"
        textAnchor="middle"
        alignmentBaseline="middle"
      >
        Loading...
      </text>
    </svg>
  );

  const handleNextImage = (event) => {
    event.stopPropagation();
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  const handlePrevImage = (event) => {
    event.stopPropagation();
    setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleClick = () => {
    navigate(`/news/${articleKey}`);
  };

  return (
    <Card
      onClick={handleClick}
      sx={{
        my:2,
        cursor: 'pointer',
        boxShadow: 'none',
        transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
          boxShadow: 15,
        },
        bgcolor: 'background.paper',
        TouchRippleProps: {
            classes: {
              child: 'article-card-ripple',
            },
          },
          '& .article-card-ripple .MuiTouchRipple-child': { 
            backgroundColor: 'rgba(0, 0, 0, 0.1)', 
          },
      }}
    >
      <Box
        sx={{ position: 'relative', width: '100%', overflow: 'hidden'}}
      >
        <LazyLoadImage
          alt={article.title}
          src={images[currentImageIndex]}
          placeholderSrc={`data:image/svg+xml;base64,${btoa(placeholderSvg)}`}
          afterLoad={handleImageLoad}
          effect="opacity"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'opacity 0.5s ease-in-out',
            opacity: imageLoaded ? 1 : 0,
            filter: 'contrast(.95) brightness(1.02)',
          }}
        />
        {images.length > 1 && (
          <>
            <IconButton
              onClick={handlePrevImage}
              sx={{
                position: 'absolute',
                top: '50%',
                left: 0,
                transform: 'translateY(-50%)',
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
              size="small"
            >
              <ArrowBackIos fontSize="small" />
            </IconButton>
            <IconButton
              onClick={handleNextImage}
              sx={{
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translateY(-50%)',
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
              size="small"
            >
              <ArrowForwardIos fontSize="small" />
            </IconButton>
          </>
        )}
      </Box>
      <CardContent sx={{ textAlign: 'center', p: 2 }}>
        <Typography variant="subtitle1" sx={{ mb: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {article.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ maxHeight: '3em', overflow: 'hidden', textOverflow: 'ellipsis', mb: 1 }}>
          {article.summary}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ArticleCard;