import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Typography, TextField, Button, CircularProgress, Checkbox, FormControlLabel, Box, Alert, Container } from '@mui/material';
import PageLayout from '../components/PageLayout';
import AccountSidebar from '../components/AccountSidebar';
import authStore from '../stores/AuthStore';
import userStore from '../stores/UserStore';
import { useTheme } from '@mui/material/styles';

const UserProfilePage = observer(() => {
  const theme = useTheme();
  const [formState, setFormState] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!formState.email || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formState.email)) {
      errors.email = 'Please enter a valid email address';
    }
    if (!formState.username || formState.username.length < 3 || formState.username.length > 15) {
      errors.username = 'Username must be between 3 and 15 characters';
    }
    if (formState.phoneNumber && !/^\d{10}$/.test(formState.phoneNumber)) {
      errors.phoneNumber = 'Phone number must be 10 digits';
    }
    if (!formState.settings?.language) {
      errors.language = 'Please select a valid language';
    }
    if (!formState.settings?.timeZone) {
      errors.timeZone = 'Please select a valid time zone';
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const loadUserData = async () => {
    try {
      if (!authStore.userId) throw new Error("Unauthorized access");

      await userStore.fetchMyUser();
      const { email, username, phoneNumber, settings = {} } = userStore.user || {};
      setFormState({
        email: email || '',
        username: username || '',
        phoneNumber: phoneNumber || '',
        settings: {
          language: settings.language || '',
          timeZone: settings.timeZone || '',
          MFA: settings.MFA || false,
          emailNotifications: settings.emailNotifications || false,
          smsNotifications: settings.smsNotifications || false,
        },
      });
    } catch (error) {
      setErrorMessage('Failed to load user data. Please try again later.');
    }
  };

  useEffect(() => {
    loadUserData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        [name]: checked,
      },
    }));
  };

  const handleEditToggle = async () => {
    if (isEditing) await loadUserData();
    setIsEditing(!isEditing);
    setSuccessMessage(null);
    setValidationErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      await userStore.updateUser(formState, authStore.userId);
      setIsEditing(false);
      setErrorMessage(null);
      setSuccessMessage('User profile saved successfully.');
    } catch (err) {
      setErrorMessage('Failed to save user profile. Please try again.');
    }
  };

  if (authStore.loading || !authStore.userId) {
    return (
      <PageLayout pageTitle="Loading...">
        <CircularProgress />
      </PageLayout>
    );
  }

  return (
    <PageLayout pageTitle="Order History">
      <Box sx={{ display: 'flex', minHeight: '100vh', mt: 4, bgcolor: theme.palette.background.default }}>
        <AccountSidebar />
        <Container sx={{ m: 4, flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="h4" gutterBottom>
            Profile
          </Typography>
          {errorMessage && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {successMessage}
            </Alert>
          )}
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={2} sx={{ mb: 2 }}>
              <TextField
                label="Email"
                name="email"
                value={formState.email || ''}
                onChange={handleInputChange}
                fullWidth
                error={!!validationErrors.email}
                helperText={validationErrors.email}
                disabled={!isEditing}
              />
              <TextField
                label="Username"
                name="username"
                value={formState.username || ''}
                onChange={handleInputChange}
                fullWidth
                error={!!validationErrors.username}
                helperText={validationErrors.username}
                disabled={!isEditing}
              />
              <TextField
                label="Phone Number"
                name="phoneNumber"
                value={formState.phoneNumber || ''}
                onChange={handleInputChange}
                fullWidth
                error={!!validationErrors.phoneNumber}
                helperText={validationErrors.phoneNumber}
                disabled={!isEditing}
              />
              <TextField
                label="Language"
                name="language"
                value={formState.settings?.language || ''}
                onChange={handleInputChange}
                fullWidth
                error={!!validationErrors.language}
                helperText={validationErrors.language}
                disabled={!isEditing}
              />
              <TextField
                label="Time Zone"
                name="timeZone"
                value={formState.settings?.timeZone || ''}
                onChange={handleInputChange}
                fullWidth
                error={!!validationErrors.timeZone}
                helperText={validationErrors.timeZone}
                disabled={!isEditing}
              />
              <FormControlLabel
                control={<Checkbox checked={formState.settings?.MFA || false} onChange={handleCheckboxChange} name="MFA" />}
                label="Enable Multi-Factor Authentication (MFA)"
                disabled={!isEditing}
              />
              <FormControlLabel
                control={<Checkbox checked={formState.settings?.emailNotifications || false} onChange={handleCheckboxChange} name="emailNotifications" />}
                label="Email Notifications"
                disabled={!isEditing}
              />
              <FormControlLabel
                control={<Checkbox checked={formState.settings?.smsNotifications || false} onChange={handleCheckboxChange} name="smsNotifications" />}
                label="SMS Notifications"
                disabled={!isEditing}
              />
            </Box>
            <Box display="flex" gap={2} mt={2}>
              <Button variant="outlined" onClick={handleEditToggle}>
                {isEditing ? 'Cancel' : 'Edit Profile'}
              </Button>
              {isEditing && (
                <Button type="submit" variant="outlined">
                  Save Changes
                </Button>
              )}
            </Box>
          </form>
        </Box>
      </Container>
      </Box>
    </PageLayout>
  );
});

export default UserProfilePage;
