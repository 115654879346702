import React from 'react';
import { Box, Typography, Button, Paper, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const PreorderBanner = () => {
  const theme = useTheme();

  return (
    <Paper
      elevation={16}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 3,
        backgroundColor: theme.palette.background.default,
        borderRadius: 2,
        mb: 4
      }}
    >
      <Typography variant="h5" fontWeight="bold" sx={{ mb: 1 }}>
        Limited-Time Presale Pricing – Preorder Now!
      </Typography>
      <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
        Be among the first to experience Ear Physics IEMs with exclusive presale pricing. Don’t miss out on this opportunity!
      </Typography>
      <Typography variant="body2" sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold', color: theme.palette.warning.main }}>
        Limited Availability – Secure Yours Today
      </Typography>
      <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
        Preorder now to receive early updates, feature expansions, and priority access to a software upgrade that will transform your IEMs into over-the-counter hearing aids, pending FDA approval.
      </Typography>
      <MuiLink component={Link} to="/preorder" underline="none" sx={{ mt: 2, width: '100%', maxWidth: { md: '300px' } }}>
        <Button
          variant="outlined"
          sx={{ width: '100%' }}
        >
          Learn More
        </Button>
      </MuiLink>
    </Paper>
  );
};

export default PreorderBanner;
