import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import cartStore from '../stores/CartStore';
import CheckoutStore from '../stores/CheckoutStore';
import authStore from '../stores/AuthStore';
import {
  Container,
  Typography,
  Button,
  CircularProgress,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Alert,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PageLayout from '../components/PageLayout';
import { useTheme } from '@mui/material/styles';
import states from '../tools/states.json';
import provinces from '../tools/canadaProvinces.json';
import countries from '../tools/countries.json';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const checkoutStore = new CheckoutStore(cartStore);

const CheckoutForm = observer(() => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [paymentIntentSecret, setPaymentIntentSecret] = useState(null);
  const [paymentReady, setPaymentReady] = useState(false);
  const [useSameAddress, setUseSameAddress] = useState(true);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [orderNotes, setOrderNotes] = useState('');

  const [cartTotal, setCartTotal] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [shippingAndHandling, setShippingAndHandling] = useState(0);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const steps = ['Shipping Information', 'Billing Information', 'Review and Pay'];

  const [billingAddress, setBillingAddress] = useState({
    street: '',
    street2: '',
    city: '',
    state: '',
    areacode: '',
    country: 'US',
  });
  const [shippingAddress, setShippingAddress] = useState({
    street: '',
    street2: '',
    city: '',
    state: '',
    areacode: '',
    country: 'US',
  });

  useEffect(() => {
    checkoutStore.loadCheckoutData();

    setCartTotal(cartStore.cartItems.reduce((prev, item) => prev += item.price * item.quantity, 0));

    if (!authStore.isAuthenticated) {
      navigate('/sign-in?redirectUrl=/checkout');
    }
  }, [authStore.isAuthenticated, navigate]);

  const handleNext = async () => {
    if (activeStep === 0) {
      if (!firstName || !lastName || !email || !shippingAddress.street || !shippingAddress.city || !shippingAddress.state || !shippingAddress.areacode || !shippingAddress.country) {
        setError('Please fill in all required fields.');
        return;
      } else {
        setError(null);
      }
      try {
        if (cartStore.cartItems.length > 0) {
          const cart = cartStore.cartItems.map((item) => ({
            product: item._id,
            quantity: item.quantity,
          }));

          const paymentData = {
            currency: 'USD',
            cart: cart,
            shipping: {
              name: `${firstName} ${lastName}`,
              address: {
                line1: shippingAddress.street,
                line2: shippingAddress.street2,
                city: shippingAddress.city,
                state: shippingAddress.state,
                postal_code: shippingAddress.areacode,
                country: shippingAddress.country,
              },
            },
          };

          const additionalDetails = {
            note: orderNotes ? orderNotes : ''
          }

          const { paymentIntent, taxes, shippingAndHandling } = await checkoutStore.createIntent(paymentData, additionalDetails);
          if (paymentIntent) {
            setTotal(paymentIntent.amount / 100);
            setTaxes(taxes);
            setShippingAndHandling(shippingAndHandling);
            setPaymentReady(true);
            setPaymentIntentSecret(paymentIntent.client_secret);
          }
        } else {
          navigate('/cart');
        }
      } catch (error) {
        console.error('Error creating payment intent:', error);
        setError('Failed to process payment options. Please try again later.');
        setPaymentReady(false);
      }
    } else if (activeStep === 1) {

      const address = useSameAddress ? shippingAddress : billingAddress

      if (useSameAddress) {
        setBillingAddress(shippingAddress);
      }

      if (!address.street || !address.city || !address.state || !address.areacode || !address.country) {
        setError('Please fill in all required billing address fields.');
        return;
      } else {
        setError(null);
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setError(null);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleCheckout = async (e) => {
    e.preventDefault();
    if (!stripe || !elements || !paymentIntentSecret) return;

    setLoading(true);
    const cardElement = elements.getElement(CardElement);

    const data = {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: `${firstName} ${lastName}`,
          email: email,
          phone: checkoutStore.phone,
          address: {
            line1: billingAddress.street,
            line2: billingAddress.street2,
            city: billingAddress.city,
            state: billingAddress.state,
            postal_code: billingAddress.areacode,
            country: billingAddress.country,
          },
        },
      },
      shipping: {
        name: `${firstName} ${lastName}`,
        address: {
          line1: useSameAddress ? billingAddress.street : shippingAddress.street,
          line2: useSameAddress ? billingAddress.street2 : shippingAddress.street2,
          city: useSameAddress ? billingAddress.city : shippingAddress.city,
          state: useSameAddress ? billingAddress.state : shippingAddress.state,
          postal_code: useSameAddress ? billingAddress.areacode : shippingAddress.areacode,
          country: useSameAddress ? billingAddress.country : shippingAddress.country,
        },
      },
    }

    try {

      const { error, paymentIntent } = await stripe.confirmCardPayment(paymentIntentSecret, data);

      if (error) {
        console.error(error);
        setError(error.message || 'Payment failed. Please try again.');
      } else if (paymentIntent.status === 'succeeded') {
        cartStore.clearCart();
        navigate('/order-confirmation');
      } else {
        setError('Payment not successful. Please try again.');
      }
    } catch (error) {
      console.error('Payment failed:', error);
      setError('Payment failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddressChange = (e, addressType) => {
    const { name, value } = e.target;
    addressType === 'billing'
      ? setBillingAddress((prev) => ({ ...prev, [name]: value }))
      : setShippingAddress((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <PageLayout pageTitle="Checkout">
      <Container component="main" sx={{ my: 4, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Paper
          component="form"
          noValidate
          onSubmit={handleCheckout}
          elevation={12}
          sx={{
            width: '100%',
            borderRadius: 2,
            p: 4,
            maxWidth: 'md',
            backgroundColor: 'rgba(240, 240, 240, 0.3)',
            borderRadius: 2,
          }}
        >

          <Stepper activeStep={activeStep} sx={{ mb: 4, mt: 2 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 && (
            <>
              <Box sx={{ mb: 4 }}>
                <Typography variant="body2" sx={{ color: 'text.primary', mb: 1 }}>First Name</Typography>
                <TextField margin="dense" required fullWidth placeholder="Enter your First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }} />
                <Typography variant="body2" sx={{ color: 'text.primary', mt: 2 }}>Last Name</Typography>
                <TextField margin="dense" required fullWidth placeholder="Enter your Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }} />
                <Typography variant="body2" sx={{ color: 'text.primary', mt: 2 }}>Email Address</Typography>
                <TextField margin="dense" required fullWidth type="email" placeholder="Enter your Email Address" value={email} onChange={(e) => setEmail(e.target.value)} InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }} />
                <Typography variant="body2" sx={{ color: 'text.primary', mt: 2 }}>Phone Number</Typography>
                <TextField margin="dense" required fullWidth placeholder="Enter your Phone Number" value={checkoutStore.phone} onChange={(e) => checkoutStore.setPhone(e.target.value)} InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }} />
                <Typography variant="body2" sx={{ color: 'text.primary', mt: 2 }}>Shipping Address</Typography>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="country-label" sx={{ color: theme.palette.text.primary, '&.Mui-focused': { color: theme.palette.text.primary } }}>Country/Region</InputLabel>
                  <Select
                    labelId="country-label"
                    id="country"
                    name="country"
                    value={shippingAddress.country}
                    label="Country/Region"
                    onChange={(e) => handleAddressChange(e, 'shippnig')}
                    InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }}
                    sx={{
                      color: theme.palette.text
                    }}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.value} value={country.value}>
                        {country.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField margin="dense" fullWidth placeholder="Enter your Street Address" name="street" value={shippingAddress.street} onChange={(e) => handleAddressChange(e, 'shipping')} InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }} />
                <TextField margin="dense" fullWidth placeholder="Apartment, Suite, etc." name="street2" value={shippingAddress.street2} onChange={(e) => handleAddressChange(e, 'shipping')} InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }} />
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  <TextField
                    margin="dense"
                    fullWidth
                    placeholder='Enter your City'
                    name="city"
                    value={shippingAddress.city}
                    onChange={(e) => handleAddressChange(e, 'shipping')}
                    InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }}
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '50%'
                      }
                    }}
                  />
                  {shippingAddress.country === 'US' && (
                    <FormControl fullWidth margin="dense" sx={{ width: { xs: '100%', sm: '50%', md: '25%' } }}>
                      <InputLabel id="state-label" sx={{ color: theme.palette.text.primary, '&.Mui-focused': { color: theme.palette.text.primary } }}>State</InputLabel>
                      <Select
                        labelId="state-label"
                        id="state"
                        name="state"
                        value={shippingAddress.state}
                        label="State"
                        onChange={(e) => handleAddressChange(e, 'shipping')}
                        InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }}
                        sx={{
                          color: theme.palette.text
                        }}
                      >
                        {states.map((state) => (
                          <MenuItem key={state.value} value={state.value}>
                            {state.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {shippingAddress.country === 'CA' && (
                    <FormControl fullWidth margin="dense" sx={{ width: { xs: '100%', sm: '50%', md: '25%' } }}>
                      <InputLabel id="state-label" sx={{ color: theme.palette.text.primary, '&.Mui-focused': { color: theme.palette.text.primary } }}>Province</InputLabel>
                      <Select
                        labelId="state-label"
                        id="state"
                        name="state"
                        value={shippingAddress.state}
                        label="State"
                        onChange={(e) => handleAddressChange(e, 'shipping')}
                        InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }}
                        sx={{
                          color: theme.palette.text
                        }}
                      >
                        {provinces.map((state) => (
                          <MenuItem key={state.value} value={state.value}>
                            {state.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {shippingAddress.country !== 'US' && shippingAddress.country !== 'CA' && (
                    <TextField margin="dense" fullWidth placeholder='State / Province / Region' name="state" value={shippingAddress.areacode} onChange={(e) => handleAddressChange(e, 'shipping')} InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }} sx={{ width: { xs: '100%', sm: '50%', md: '25%' } }}></TextField>
                  )}
                  <TextField margin="dense" fullWidth placeholder={`${shippingAddress.country === 'US' ? 'ZIP' : 'Postal'} Code`} name="areacode" value={shippingAddress.areacode} onChange={(e) => handleAddressChange(e, 'shipping')} InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }} sx={{ width: { xs: '100%', sm: '50%', md: '25%' } }}></TextField>
                  <TextField
                    onChange={(e) => setOrderNotes(e.target.value)}
                    placeholder="Order notes"
                    value={orderNotes}
                    fullWidth
                    margin="dense"
                    multiline={true}
                    rows={2}
                    InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }}
                  />
                </Box>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Button variant="outlined" onClick={handleNext} sx={{
                  mt: 1,
                  mr: 1,
                  width: {
                    xs: '100%',
                    md: '300px'
                  }
                }}>
                  Continue
                </Button>
              </Box>
            </>
          )}
          {activeStep === 1 && (
            <>
              {paymentReady && (
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6">Payment Information</Typography>
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="body1" sx={{ mb: 2, mt: 2 }}>Cart Total: ${cartTotal.toFixed(2)}</Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>Shipping and Handling: ${(shippingAndHandling ? shippingAndHandling : 0).toFixed(2)}</Typography>
                    {taxes && taxes > 0 ? (<Typography variant="body1" sx={{ mb: 2 }}>Taxes: ${(taxes ? taxes : 0).toFixed(2)}</Typography>) : <></>}
                    <Typography variant="body1" sx={{ mb: 2 }}>Total: ${(total ? total : cartTotal).toFixed(2)}</Typography>
                  </Box>
                  <Typography variant="h6" sx={{ color: 'text.primary', mt: 2 }}>Billing Address</Typography>
                  <FormControlLabel control={<Checkbox checked={useSameAddress} disabled={!paymentReady} onChange={() => setUseSameAddress(!useSameAddress)} color="primary" />} label="Use the same address as shipping" sx={{ mt: 2 }} />

                  {!useSameAddress && (
                    <>
                      <TextField margin="dense" fullWidth placeholder="Enter your Street Address" name="street" value={billingAddress.street} disabled={!paymentReady} onChange={(e) => handleAddressChange(e, 'billing')} InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }} />
                      <TextField margin="dense" fullWidth placeholder="Apartment, Suite, etc." name="street2" value={billingAddress.street2} disabled={!paymentReady} onChange={(e) => handleAddressChange(e, 'billing')} InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }} />
                      <FormControl fullWidth margin="dense">
                        <InputLabel id="country-label" sx={{ color: theme.palette.text.primary, '&.Mui-focused': { color: theme.palette.text.primary } }}>Country/Region</InputLabel>
                        <Select
                          labelId="country-label"
                          id="country"
                          name="country"
                          value={billingAddress.country}
                          label="Country/Region"
                          onChange={(e) => handleAddressChange(e, 'shippnig')}
                          InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }}
                          sx={{
                            color: theme.palette.text
                          }}
                        >
                          {countries.map((country) => (
                            <MenuItem key={country.value} value={country.value}>
                              {country.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField margin="dense" fullWidth placeholder="Enter your Street Address" name="street" value={billingAddress.street} onChange={(e) => handleAddressChange(e, 'billing')} InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }} />
                      <TextField margin="dense" fullWidth placeholder="Apartment, Suite, etc." name="street2" value={billingAddress.street2} onChange={(e) => handleAddressChange(e, 'billing')} InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }} />
                      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <TextField
                          margin="dense"
                          fullWidth
                          placeholder='Enter your City'
                          name="city"
                          value={billingAddress.city}
                          onChange={(e) => handleAddressChange(e, 'billing')}
                          InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }}
                          sx={{
                            width: {
                              xs: '100%',
                              sm: '100%',
                              md: '50%'
                            }
                          }}
                        />
                        {billingAddress.country === 'US' && (
                          <FormControl fullWidth margin="dense" sx={{ width: { xs: '100%', sm: '50%', md: '25%' } }}>
                            <InputLabel id="state-label" sx={{ color: theme.palette.text.primary, '&.Mui-focused': { color: theme.palette.text.primary } }}>State</InputLabel>
                            <Select
                              labelId="state-label"
                              id="state"
                              name="state"
                              value={billingAddress.state}
                              label="State"
                              onChange={(e) => handleAddressChange(e, 'billing')}
                              InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }}
                              sx={{
                                color: theme.palette.text
                              }}
                            >
                              {states.map((state) => (
                                <MenuItem key={state.value} value={state.value}>
                                  {state.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {billingAddress.country === 'CA' && (
                          <FormControl fullWidth margin="dense" sx={{ width: { xs: '100%', sm: '50%', md: '25%' } }}>
                            <InputLabel id="state-label" sx={{ color: theme.palette.text.primary, '&.Mui-focused': { color: theme.palette.text.primary } }}>Province</InputLabel>
                            <Select
                              labelId="state-label"
                              id="state"
                              name="state"
                              value={billingAddress.state}
                              label="State"
                              onChange={(e) => handleAddressChange(e, 'billing')}
                              InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }}
                              sx={{
                                color: theme.palette.text
                              }}
                            >
                              {provinces.map((state) => (
                                <MenuItem key={state.value} value={state.value}>
                                  {state.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {billingAddress.country !== 'US' && billingAddress.country !== 'CA' && (
                          <TextField margin="dense" fullWidth placeholder='State / Province / Region' name="state" value={billingAddress.areacode} onChange={(e) => handleAddressChange(e, 'billing')} InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }} sx={{ width: { xs: '100%', sm: '50%', md: '25%' } }}></TextField>
                        )}
                        <TextField margin="dense" fullWidth placeholder={`${billingAddress.country === 'US' ? 'ZIP' : 'Postal'} Code`} name="areacode" value={billingAddress.areacode} onChange={(e) => handleAddressChange(e, 'billing')} InputProps={{ style: { backgroundColor: 'background.paper', color: 'text.primary' } }} sx={{ width: { xs: '100%', sm: '50%', md: '25%' } }}></TextField>
                      </Box>
                    </>
                  )}

                </Box>
              )}
              <Box sx={{ mb: 2 }}>
                <Button
                  variant="outlined"
                  onClick={handleNext}
                  sx={{
                    mt: 2, mr: 2, width: {
                      xs: '100%',
                      md: '300px'
                    }
                  }}
                >
                  Continue
                </Button>
                <Button
                  onClick={handleBack}
                  sx={{
                    mt: 2, mr: 1, color: theme.palette.primary.dark, width: {
                      xs: '100%',
                      md: '300px'
                    }
                  }}
                  variant='outlined'
                >
                  Back
                </Button>

              </Box>
            </>
          )}
          {activeStep === 2 && (
            <>
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6">Review and Confirm</Typography>
                <Box sx={{ mb: 4 }}>
                  <Typography variant="h5" sx={{ mb: 2 }}>Order Summary</Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>Cart Total: ${cartTotal.toFixed(2)}</Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>Shipping and Handling: ${shippingAndHandling.toFixed(2)}</Typography>
                  {taxes && taxes > 0 ? (<Typography variant="body1" sx={{ mb: 2 }}>Taxes: ${taxes.toFixed(2)}</Typography>) : <></>}
                  <Typography variant="body1" sx={{ mb: 2 }}>Total: ${(total ? total : cartTotal).toFixed(2)}</Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Shipping Address:
                    {` ${Object.values(shippingAddress).filter(Boolean).join(', ')}`}
                  </Typography>

                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Billing Address:
                    {` ${Object.values(billingAddress).filter(Boolean).join(', ')}`}
                  </Typography>
                  <Box sx={{ mt: 2, p: 2, border: `1px solid ${theme.palette.divider}`, borderRadius: 1, backgroundColor: theme.palette.background.paper }}>
                    <CardElement options={{ style: { base: { color: theme.palette.text.primary, fontSize: '16px', '::placeholder': { color: theme.palette.text.secondary } }, invalid: { color: '#ff5252' } } }} />
                  </Box>
                </Box>
                <Button
                  type="submit"
                  variant="outlined"
                  fullWidth
                  disabled={!paymentReady || loading}
                  sx={{
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                    mb: 2,
                    '&:hover': { backgroundColor: 'primary.dark' }
                  }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Pay Now'}
                </Button>
                <Button
                  onClick={handleBack}
                  variant='outlined'
                  fullWidth
                  sx={{
                    color: theme.palette.primary.dark,
                  }}
                >
                  Back
                </Button>

              </Box>
              <Box sx={{ mb: 2 }}>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </Box>
            </>
          )}

        </Paper>
      </Container>
    </PageLayout>
  );
});

const CheckoutPage = () => (
  stripePromise && (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  )
);

export default CheckoutPage;
