import React from 'react';
import PageLayout from '../components/PageLayout';
import { Box, Typography, Divider, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/styles';

const PreorderPage = () => {
  const theme = useTheme();
  return (
    <PageLayout pageTitle="Preorder Information">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 6, px: 3 }}>
        <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 4 }}>
          Ear Physics Preorder Overview
        </Typography>
        
        <Box sx={{ width: '100%', maxWidth: 800 }}>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Preorders are now open for Ear Physics In-Ear Monitors, crafted for exceptional comfort and sound quality. 
            Secure early access and be among the first to experience Ear Physics' innovative audio technology.
          </Typography>

          <Box sx={{ my: 4 }}>
            <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
              Future-Proof Audio Innovation
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              With Ear Physics IEMs, you're not only investing in world-class audio but also in groundbreaking hearing aid capabilities. 
              Pending FDA approval, these IEMs will support over-the-counter (OTC) hearing aid functionality, offering a powerful fusion 
              of high-fidelity sound and personalized hearing assistance.
            </Typography>
          </Box>

          <Box sx={{ my: 4 }}>
            <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
              Why Preorder?
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Join a community of audio enthusiasts and early adopters.
            </Typography>
            <Typography variant="body1">
              Preordering reserves a limited release from the initial production run, granting you early access to Ear Physics technology 
              designed for musicians, sound engineers, audiophiles, and gamers who demand premium sound in all settings.
            </Typography>
          </Box>

          <Box sx={{ my: 4 }}>
            <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
              Delivery and Manufacturing Timeline
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Transparent and reliable updates.
            </Typography>
            <Typography variant="body1">
              Manufacturing starts soon after the preorder window closes. Delivery is estimated within 8-12 weeks post-production, with 
              updates provided on progress and delivery timelines.
            </Typography>
          </Box>

          <Box sx={{ my: 4 }}>
            <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
              Beta Period Updates
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Access the latest advancements and features.
            </Typography>
            <Typography variant="body1">
              Preorder customers benefit from continuous updates, enhancements, and early access to new features, including planned OTA 
              hearing aid capabilities once approved.
            </Typography>
            <Divider sx={{ my: 3 }} />
          </Box>

          <Box sx={{ my: 4 }}>
            <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
              Ongoing Support and Feature Enhancements
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Your feedback helps shape our technology.
            </Typography>
            <Typography variant="body1">
              Ear Physics values customer insights, which inform product refinements and ensure optimal performance across use cases, from 
              live performances to immersive listening at home.
            </Typography>
          </Box>

          <Box sx={{ my: 4 }}>
            <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
              Exclusive Preorder Benefits
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Preordering provides the following advantages:
            </Typography>
            <Divider sx={{ my: 3 }} />
            <Box>
              <Typography variant="body2" sx={{ mb: 2 }}>
                <strong>Guaranteed Availability:</strong> Secure your unit from our limited initial production.
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                <strong>Early Access to Firmware & Updates:</strong> Get feature enhancements, including upcoming over-the-counter hearing aid functionality upon FDA approval.
              </Typography>
              <Typography variant="body2">
                <strong>Direct Support & Feedback:</strong> Early adopters enjoy prioritized support and the chance to influence product development.
              </Typography>
            </Box>
          </Box>
          
          <Button
            component={Link}
            to="/products"
            variant="outlined"
            sx={{
              mt: 6,
            }}
          >
            Preorder Now
          </Button>
        </Box>
      </Box>
    </PageLayout>
  );
};

export default PreorderPage;
