import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import {
  Drawer,
  Box,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  IconButton,
  ThemeProvider,
  Avatar
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { darkTheme } from '../themes/EarPhysicsTheme';
import cartStore from '../stores/CartStore';

const CartDrawer = observer(({ open, toggleCartDrawer, drawerWidth }) => (
  <ThemeProvider theme={darkTheme}>
    <Drawer
        anchor="right"
        open={open}
        onClose={toggleCartDrawer(false)}
        onOpen={toggleCartDrawer(true)}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1,}}
        PaperProps={{
            sx: {
              width: drawerWidth,
              backgroundColor: 'black',
              color: darkTheme.palette.text.primary,
            },
        }}
    >
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
        <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Your Cart</Typography>
            <IconButton color="inherit" onClick={toggleCartDrawer(false)}>
            <CloseIcon />
            </IconButton>
        </Toolbar>
        <List>
            {cartStore.cartItems.map((item) => (
            <ListItem key={item._id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Avatar
                    src={item.productImage || 'https://via.placeholder.com/64?text=No+Image'}
                    alt={item.name}
                    variant="square"
                    sx={{
                        width: 64,
                        height: 64,
                        mr: 2,
                        bgcolor: item.productImage ? 'transparent' : 'grey.200',
                        borderRadius: 2,
                        boxShadow: '0px 4px 10px rgba(25, 25, 25, .5)',
                    }}
                />
                <ListItemText primary={item.name} secondary={`$${item.price} x ${item.quantity}`} />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        sx={{
                        width: 32,
                        height: 32,
                        color: 'white',
                        backgroundColor: 'primary.main',
                        '&:hover': {
                            backgroundColor: 'primary.dark',
                        },
                        }}
                        onClick={() => cartStore.decreaseQuantity(item._id)}
                    >
                        <RemoveIcon />
                    </IconButton>
                    <Typography variant="body2" component="span" mx={2}>
                        {item.quantity}
                    </Typography>
                    <IconButton
                        sx={{
                        width: 32,
                        height: 32,
                        color: 'white',
                        backgroundColor: 'primary.main',
                        '&:hover': {
                            backgroundColor: 'primary.dark',
                        },
                        }}
                        onClick={() => cartStore.increaseQuantity(item._id)}
                    >
                        <AddIcon />
                    </IconButton>
                    </Box>

            </ListItem>
            ))}
        </List>
        <Box sx={{ mt: 2 }}>
            <Typography variant="h6" align="center">
            Total: ${cartStore.total.toFixed(2)}
            </Typography>
            <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={cartStore.cartItems.length === 0}
            component={Link}
            to="/checkout"
            sx={{ mt: 2 }}
            onClick={toggleCartDrawer(false)}
            >
            Checkout
            </Button>
        </Box>
        </Box>
    </Drawer>
  </ThemeProvider>
));

export default CartDrawer;
