import { createTheme } from '@mui/material/styles';

const darkColors = {
  primaryMain: '#1c1c1e',
  primaryContrast: '#fff',
  secondaryMain: 'rgba(241, 241, 242, 0.7)',
  backgroundPaper: '#252525',
  textPrimary: '#fff',
  textSecondary: 'rgba(255, 255, 255, 0.7)',
  dividerColor: 'rgba(74, 74, 79, 0.8)',
  pageBackground: '#000',
  inputFocusBg: '#1c1c1e',
  inputBorderColor: '#444',
};

const lightColors = {
  primaryMain: '#f9f9f9',
  primaryContrast: '#333344',
  secondaryMain: 'rgba(125, 125, 125, 0.9)',
  backgroundPaper: '#ffffff',
  textPrimary: '#2c2c2e',
  textSecondary: 'rgba(44, 44, 46, 0.7)',
  dividerColor: 'rgba(179, 179, 179, 0.7)',
  pageBackground: '#ffffff',
  inputFocusBg: '#f0f0f0',
  inputBorderColor: '#333344',
};

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: darkColors.primaryMain, contrastText: darkColors.primaryContrast },
    secondary: { main: darkColors.secondaryMain },
    background: { paper: darkColors.backgroundPaper, default: darkColors.pageBackground },
    text: { primary: darkColors.textPrimary, secondary: darkColors.textSecondary },
    divider: darkColors.dividerColor,
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderColor: darkColors.primaryContrast,
          color: darkColors.primaryContrast,
          "&:hover": { backgroundColor: darkColors.secondaryMain },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: darkColors.primaryContrast,
          '&.Mui-checked': {
            color: darkColors.primaryContrast,
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          backgroundColor: 'background.paper', 
          padding: '16px', 
          borderRadius: '4px', 
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: 'text.primary', 
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: 'primary.main', 
          '&.Mui-completed': {
            "color": 'success.main'
          }
        },
        active: {
          color: 'secondary.main',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: darkColors.dividerColor,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: darkColors.inputBorderColor,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: darkColors.inputBorderColor,
          },
        },
        input: {
          backgroundColor: darkColors.inputFocusBg,
        },
      },
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: lightColors.primaryMain, contrastText: lightColors.primaryContrast },
    secondary: { main: lightColors.secondaryMain },
    background: { paper: lightColors.backgroundPaper, default: lightColors.pageBackground },
    text: { primary: lightColors.textPrimary, secondary: lightColors.textSecondary },
    divider: lightColors.dividerColor,
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderColor: lightColors.primaryContrast,
          color: lightColors.primaryContrast,
          "&:hover": { borderColor: lightColors.secondaryMain },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: lightColors.primaryContrast,
          '&.Mui-checked': {
            color: lightColors.primaryContrast,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: lightColors.dividerColor,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: lightColors.inputBorderColor,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: lightColors.inputBorderColor,
          },
        },
      },
    },
  },
});

export { darkTheme, lightTheme };
