import { makeAutoObservable } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class ProductStore {
  products = [];
  selectedProduct = null;

  constructor() {
    makeAutoObservable(this);
  }

  loadProducts() {
    apiHandler.get('/products').then((response) => {
      this.products = response.products;
    });
  }

  loadProduct(id) {
    this.selectedProduct = this.products.find((product) => product._id === id);
    if (!this.selectedProduct) {
      apiHandler.get(`/products/${id}`).then((response) => {
        this.selectedProduct = response.product;
      })
    }
  }

  clearSelectedProduct() {
    this.selectedProduct = null;
  }

}

const productStore = new ProductStore();
export const useProductStore = () => productStore;
