import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useProductStore } from '../stores/ProductStore';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Typography, Button, CardMedia, Box, Chip, List, ListItem, ListItemText, Divider, useMediaQuery, IconButton } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import PageLayout from '../components/PageLayout';
import cartStore from '../stores/CartStore';
import { useTheme } from '@mui/material/styles';

const ProductDetailsPage = observer(() => {
  const { id } = useParams();
  const productStore = useProductStore();
  const [selectedImage, setSelectedImage] = useState(null);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const product = productStore.selectedProduct;
  const [isHovered, setIsHovered] = useState(false);
  const scrollContainerRef = React.useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    productStore.loadProduct(id);
  }, [id, productStore]);

  useEffect(() => {
    if (product) {
      const images = [
        ...(product.attachments || []),
        ...(product.primaryImage && !product.attachments?.some((img) => img === product.primaryImage)
          ? [product.primaryImage]
          : []),
      ];
      if (images.length > 0) {
        setSelectedImage(images[0].url);
      }
    }
  }, [product]);

  if (!product) return null;

  const images = [
    ...(product.primaryImage ? [product.primaryImage] : []),
    ...(product.attachments || []),
  ];

  const formatDate = (date) => {
    if (!date) return 'N/A';
    const formattedDate = new Date(date);
    return formattedDate.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
    });
  };

  const formatKey = (key) => {
    const words = key.match(/[A-Z][a-z]+|[0-9]+|[A-Z]+(?![a-z])/g);
    return words.join(' ');
  };

  const addAndNavigate = () => {
    cartStore.addToCart(product);
    navigate('/cart');
  };

  const renderList = (title, items) => (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <List dense>
        {items &&
          Object.entries(items).map(([key, value], index) => {
            if (typeof value === 'object') {
              const formattedValue = Object.entries(value).map(([innerKey, innerValue]) => (
                <Typography variant="body2" key={innerKey}>
                  <strong>{formatKey(innerKey)}:</strong> {innerValue}
                </Typography>
              ));
              return (
                <ListItem key={index} disableGutters>
                  <ListItemText primary={formattedValue} />
                </ListItem>
              );
            } else {
              return (
                <ListItem key={index} disableGutters>
                  <ListItemText primary={`${formatKey(key)}: ${value}`} />
                </ListItem>
              );
            }
          })}
      </List>
    </Box>
  );

  const renderDimensions = () => {
    const { length, width, height, weight } = product.dimensions || {};
    return (
      <Box sx={{ mt: 3 }}>
        <Typography variant="body2">
          <strong>Dimensions:</strong> {length} x {width} x {height} cm
        </Typography>
        <Typography variant="body2">
          <strong>Weight:</strong> {weight} kg
        </Typography>
      </Box>
    );
  };

  const renderStockStatus = () => {
    if (product.productType.includes('pre-order')) return 'Preorder';
    if (product.stock > 0) return 'In Stock';
    if (product.allowBackorders) return 'Backorder';
    if (product.stock === 0) return 'Out of Stock';
    if (product.productType === 'digital') return 'Digital';
    if (product.productType === 'made-to-order') return 'Made to Order';
    return 'Unavailable';
  };

  const stockStatus = renderStockStatus();
  const isAvailableForOrder = stockStatus !== 'Out of Stock' && stockStatus !== 'Unavailable';

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: direction === 'left' ? -100 : 100,
        behavior: 'smooth',
      });
    }
  };

  const formatPrice = (price) => new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: product.currency || 'USD'
  }).format(price);

  return (
    <PageLayout pageTitle={product.name}>
      <Box sx={{ p: { xs: 2, md: 6 }, width: { lg: '1200px' }, maxWidth: '100%', mx: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 4,
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ width: '100%', maxWidth: { md: '50%' } }}>
            <Box sx={{ width: '100%', mb: 2 }}>
              <CardMedia
                component="img"
                image={selectedImage}
                alt={product.name}
                sx={{
                  width: '100%',
                  borderRadius: 2,
                  aspectRatio: '1/1',
                  objectFit: 'contain',
                  bgcolor: 'background.paper',
                  border: '1px solid',
                  borderColor: 'grey.200'
                }}
              />
            </Box>

            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
              onMouseOver={() => setIsHovered(true)}
              onMouseOut={() => setIsHovered(false)}
            >
              {isHovered && (
                <>
                  <IconButton
                    onClick={() => handleScroll('left')}
                    onMouseOver={() => setIsHovered(true)}
                    onMouseOut={() => setIsHovered(false)}
                    sx={{
                      position: 'absolute',
                      left: 0,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      zIndex: 2,
                      bgcolor: 'rgba(0, 0, 0, 0.5)',
                      color: 'white',
                      '&:hover': {
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                      },
                    }}
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                  <IconButton
                    onClick={() => handleScroll('right')}
                    onMouseOver={() => setIsHovered(true)}
                    onMouseOut={() => setIsHovered(false)}
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      zIndex: 2,
                      bgcolor: 'rgba(0, 0, 0, 0.5)',
                      color: 'white',
                      '&:hover': {
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                      },
                    }}
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                </>
              )}
              <Box
                ref={scrollContainerRef}
                sx={{
                  display: 'flex',
                  gap: 2,
                  overflowX: 'auto',
                  position: 'relative',
                  '-ms-overflow-style': 'none',
                  'scrollbar-width': 'none',
                  '&::-webkit-scrollbar': { display: 'none' },
                  width: '100%',
                  justifyContent: 'flex-start',
                  p: 1,
                }}
              >
                {images.map((img, index) => (
                  <Box
                    key={index}
                    onClick={() => setSelectedImage(img.url)}
                    sx={{
                      flexShrink: 0,
                      borderRadius: 2,
                      cursor: 'pointer',
                      border: selectedImage === img.url ? '2px solid' : '1px solid',
                      borderColor: selectedImage === img.url ? 'primary.main' : 'grey.300',
                      transition: 'border-color 0.3s',
                      width: { xs: '60px', md: '80px' },
                      height: { xs: '60px', md: '80px' },
                      boxShadow: selectedImage === img.url ? 3 : 1,
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={img.url}
                      alt={product.name}
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 2,
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: 1, pr: 4, maxWidth: { md: '50%' } }}>
            <Typography variant="h4" gutterBottom>
              {product.name}
            </Typography>
            <Typography variant="body1" paragraph sx={{ mb: 2 }}>
              {product.description}
            </Typography>
            {product.descriptionHtml && (
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
                paragraph
                sx={{ mb: 2 }}
              />
            )}
            <Box component="div" sx={{ mt: 2, mb: 2 }}>
              {product.originalPrice && product.originalPrice > product.price ? (
                <>
                  <Typography
                    variant="h6"
                    component="span"
                    color="text.secondary"
                    sx={{ textDecoration: 'line-through', mr: '0.5rem' }}
                  >
                    List Price: {formatPrice(product.originalPrice)}
                  </Typography>
                  <br/>
                  <Typography variant="h6" component="span"  sx={{ fontWeight: 'bold' }}>
                    Limited-Time Pre-order: {formatPrice(product.price)}
                  </Typography>
                </>
              ) : (
                <Typography variant="h6" component="span"  sx={{ fontWeight: 'bold' }}>
                  {formatPrice(product.price)}
                </Typography>
              )}
            </Box>
            <Chip
              label={stockStatus}
              onClick={() => isAvailableForOrder ? addAndNavigate() : function () { }}
              color={
                stockStatus === 'Out of Stock'
                  ? 'error'
                  : stockStatus === 'Preorder' || stockStatus === 'Backorder'
                    ? 'warning'
                    : stockStatus === 'Digital' || stockStatus === 'Made to Order'
                      ? 'info'
                      : 'success'
              }
              sx={{ mb: 2, cursor: 'pointer' }}
            />
            <Typography variant="body2" sx={{ mb: 2 }}>
              <strong>Availabile:</strong> {formatDate(product.availabilityDate)}
            </Typography>
            {renderDimensions()}
            {/* <Typography variant="body2" sx={{ mt: 2 }}>
              <strong>Lead Time:</strong> {product.leadTime} days
            </Typography> */}
            <Box sx={{ mt: 3 }}>
              <Button
                component={Link}
                to="/cart"
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => cartStore.addToCart(product)}
                disabled={!isAvailableForOrder}
              >
                {stockStatus === 'Preorder' ? 'Preorder Today' : 'Add to Cart'}
              </Button>
            </Box>
          </Box>
        </Box>

        <Divider sx={{ my: 4 }} />

        <Box sx={{ maxWidth: '100%', pr: 4 }}>
          {renderList('Key Features', product.specifications?.KeyFeatures)}
          <Divider sx={{ my: 4 }} />
          {renderList('Technical Specifications', product.specifications?.TechnicalSpecifications)}
          <Divider sx={{ my: 4 }} />
          {renderList('Included Accessories', product.specifications?.IncludedAccessories)}
        </Box>
      </Box>
    </PageLayout>
  );
});

export default ProductDetailsPage;
